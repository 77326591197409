import React, { useContext, useEffect, useState } from "react";
// import { Star } from "../components/card";
import Button from "../components/form-kit/button";
import Search from "../components/filter/search";
// import FilterButton from "../components/filter/filterButton";
// import RangeWithInput from "../components/filter/rangewithInput";
// import Checkbox from "../components/filter/checkbox";
// import Card from "../components/card";
// import ReactPaginate from "react-paginate";
// import Featured, { FeaturedList } from "../components/featured";
// import BlogCard from "../components/blog/blogCard";
import Section from "../layout/section";
// import Footer from "../layout/footer";
import { Link, useNavigate } from "react-router-dom";
import Page from "../layout/page";
import axios from "../api/axios";
import BlogList from "../components/blogList";
// import qs from "qs";
import Loader from "../layout/loader";
import AuthContext from "../context/AuthProvider";

import { Helmet } from "react-helmet";

export default function Home() {
  const [blogs, setBlogs] = useState(null);
  const navigate = useNavigate();
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [days, setDays] = useState([0, 999]);
  const [query, setQuery] = useState({});
  const [disabled, setDisabled] = useState(true);
  const { auth } = useContext(AuthContext);
  const changeHandler = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      setDisabled(true);
      setQuery((prev) => ({
        ...prev,
        filters: {
          location: {
            $containsi: search,
          },
        },
      }));
      return;
    }
    setDisabled(false);
  };
  useEffect(() => {
    const getBlogs = async () => {
      try {
        const res = await axios.get(
          "/blogs?populate=hero&&pagination[pageSize]=3"
        );
        setBlogs(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, []);
  const daysHandler = (value) => {
    setDays(value.value);
    if (search !== "") {
      setDisabled(false);
    }
  };
  const submitHandler = () => {
    if (search === "") {
    }
    setQuery((prev) => ({
      ...prev,
      filters: {
        location: {
          $containsi: search,
        },
      },
    }));
    //console.log(qs.stringify({ ...query }, { encodeValuesOnly: true }));
  };
  if (!blogs) {
    return <Loader />;
  }
  return (
    <Page>
      <Helmet>
        <title>Home-Travel Swap Shop</title>
        <link rel="canonical" href="http://travelswapshop.com" />
      </Helmet>
      <Section>
        <main className="flex flex-col flex-col-reverse md:flex-row p-8 justify-center items-center">
          <div className="flex justify-center flex-col w-[90%] md:w-[52%] px-4 gap-6">
            <h1 className="text-h4 font-semibold leading-[3rem]">
              Your One-Stop Travel Itinerary Resource.&nbsp;
              <br />
              <span className="text-[60px] text-blue font-extrabold">
                Share
              </span>{" "}
              your travel itineraries with fellow travellers!
            </h1>
            <p className="text-lg text-darkgrey w-[90%] font-medium">
              We all know creating a travel itinerary takes both time and
              dedication and sometimes it's hard to even know where to start.
              Travel blogs can be time consuming to read through. Travel Swap
              Shop is a new and innovative way to share your carefully crafted
              itineraries and access other traveler's itineraries.
            </p>

            <p className="text-lg text-darkgrey w-[90%] font-medium">
              Save time and energy by searching for a pre-made itinerary you can
              use or adapt and share itineraries you have already created!
            </p>
            <div className="w-100%">
              <Link to={auth.jwt ? "/accounts/my-itineraries" : "/sign-up"}>
                <Button primary="true">Share an Itinerary</Button>
              </Link>
            </div>
          </div>
          <div className="w-[70%] md:w-[30%] rotate-3">
            <img
              src="./img/homepage-hero-new.jpg"
              alt="A traveler on top of hill"
            />
          </div>
        </main>

        <div className="relative w-[80%] m-auto">
          <Search
            value={search}
            onSearch={changeHandler}
            days={days}
            daysChange={daysHandler}
            onSubmit={submitHandler}
            disabled={disabled}
          />
        </div>
      </Section>
      <Section className="w-full bg-bgblue -mt-[100px] pt-[150px] pb-4">
        <BlogList pageSize={6} query={query} setDays={setDays} days={days} />
      </Section>
      <Section>
        <div className="flex flex-col items-center">
          <div className="flex flex-col md:flex-row p-8 items-center justify-around">
            <div className="w-[75%] md:w-[35%] flex justify-center">
              <img src="./img/share.svg" alt="Share the itinerary" />
            </div>
            <div className="flex flex-col w-[100%] my-4 md:my-0 md:w-[50%]">
              <span className="text-black text-h4 font-bold">How it works</span>
              {/* <span className="text-blue w-[60%] text-h4 font-bold">
                WANT TO SELL YOUR ITINERARIES?
              </span> */}
              <SellList
                title="Create an account"
                img="./img/create_account.png"
                text="Create Join Travel Swap Shop and have access to both share and purchase travel itineraries."
              />
              <SellList
                title="Set a price for your itinerary"
                img="./img/price.png"
                right
                text={`<div>
                    Some things to consider when determining the price:
                    <ul class="ml-5">
                      <li style="list-style-type: circle">
                        the time you spent creating the itinerary
                      </li>
                       <li style="list-style-type: circle">
                        the length of the itinerary
                      </li>
                       <li style="list-style-type: circle">
                        how detailed the itinerary is
                      </li>
                    </ul>
                  </div>`}
              />
              <SellList
                title="Upload your itinerary"
                img="./img/upload.png"
                text={`If you're like me, your itineraries take many different formats. <a href="./TSS What makes a great itinerary.docx" class="text-blue">Click here</a> to use our simple format for your past and future itineararies.`}
              />
            </div>
          </div>
          <div className="my-6">
            <Button
              onClick={() => {
                navigate("how-it-works");
              }}
              primary="true"
            >
              Learn More
            </Button>
          </div>
        </div>
      </Section>

      {/* <Section className="bg-bgblue">
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                FEATURED ITINERARY
              </span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                Viverra vitae congue eu consequat. Mauris rhoncus aenean vel
                elit scelerisque mauris
              </span>
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-4 my-8">
            <FeaturedList />
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[80%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">OUR BLOGS</span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                Morbi tincidunt ornare massa eget egestas purus. Fermentum et
                sollicitudin ac orci. Viverra aliquet eget sit amet.
              </span>
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-4 my-8">
            {blogs.data.map((e, i) => (
              <BlogCard
                key={e.id}
                title={e.attributes.title}
                text={e.attributes.description}
                img={e.attributes.hero.data.attributes.url}
                date={e.attributes.publishedAt}
                id={e.id}
              />
            ))}
          </div>
          <div className="my-6">
            <Link to="/blogs">
              <Button primary="true">Read More</Button>
            </Link>
          </div>
        </div>
      </Section> */}
    </Page>
  );
}

function SellList({ right, img, title, text }) {
  return (
    <div className={`flex my-4 w-full ${right ? "justify-end" : ""}`}>
      <div className="flex gap-2 w-[60%] items-center">
        <img src={img} width={"20%"} alt="title" />
        <div className="flex flex-col">
          <span className="text-blue text-md font-bold">{title}</span>
          <div
            className="text-grey text-sm font-semibold w-[80%]"
            dangerouslySetInnerHTML={{ __html: text }}
          >
            {/* {text} */}
          </div>
        </div>
      </div>
    </div>
  );
}
