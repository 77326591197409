import React, { useContext, useEffect, useState } from "react";
import { useOutlet, useOutletContext } from "react-router-dom";
import axios from "../../api/axios";
import Button from "../../components/form-kit/button";
import AuthContext from "../../context/AuthProvider";
import { TailSpin } from "react-loader-spinner";
export default function Payment() {
  const [link, setLink] = useState(null);
  const { auth } = useContext(AuthContext);
  useEffect(() => {
    const getLink = async () => {
      try {
        const res = await axios({
          method: "post",
          url: "users-permissions/users/stripe",
          headers: {
            Authorization: `Bearer ${auth.jwt}`,
          },
        });
        // console.log(res.data);
        setLink(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (auth.jwt) getLink();
  }, []);
  if (!link) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <TailSpin
          height="80"
          width="80"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  if (link.charges_enabled) {
    return (
      <div className="flex flex-col min-h-[60vh] justify-center items-center">
        You have succesfully linked your stripe account!
        <a href={link.url}>
          <Button>Go to your stripe account</Button>
        </a>
      </div>
    );
  }

  return (
    <div className="flex min-h-[60vh] justify-center items-center">
      <a href={link.url}>
        <Button>Connect Stripe</Button>
      </a>
    </div>
  );
}
