import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from "../../components/form-kit/button";
import Card from "../../components/card";
import {
  Link,
  NavLink,
  // Outlet,
  // useNavigate,
  useOutlet,
  useOutletContext,
} from "react-router-dom";
import Select from "react-select";
import Checkbox from "../../components/filter/checkbox";
import { useDropzone } from "react-dropzone";
import data from "../../utils/new_file.json";
import axios from "../../api/axios";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { TailSpin } from "react-loader-spinner";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

export function ItineraryMain() {
  const outlet = useOutlet();
  const [list, setList] = useState();
  const { data, jwt } = useOutletContext();

  useState(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          "/users/me?populate[written][populate][0]=images",
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        console.log(res);
        setList(res.data.written);
        // console.log(list);
      } catch (errors) {
        console.log(errors);
      }
    };
    getData();
  });
  return (
    <div className="w-full">
      {outlet || (
        <div className="flex flex-col items-center gap-3">
          <div className="text-h4 text-blue font-bold">
            Manage Your Itineraries
          </div>
          <div>Add and manage the itineraries you have listed</div>
          {data.stripe_account ? (
            <NavLink to="add-new">
              <Button>POST NEW ITINERARY</Button>
            </NavLink>
          ) : (
            <div className="text-h6 text-blue">
              Please connect Stripe account before sharing your itineraries
            </div>
          )}

          {list ? (
            <div className="px-4 flex flex-wrap justify-center gap-2 ">
              {list.length ? (
                list.map((e) => (
                  <Link
                    to={`/single-itinerary/${e.id}`}
                    className="relative"
                    key={e.id}
                  >
                    <div
                      className={
                        e.publishedAt
                          ? `border-blue border-[1px]`
                          : `border-red border-[1px]`
                      }
                    >
                      <div className="absolute top-0 right-0">
                        <NavLink to={`edit/${e.id}`}>
                          <Button>Edit</Button>
                        </NavLink>
                      </div>

                      <Card
                        title={e.title}
                        price={e.price}
                        days={e.days}
                        score={e.rating}
                        location={e.location}
                        author={data.username}
                        img={e.images[0].url}
                      />
                    </div>
                  </Link>
                ))
              ) : (
                <div>You have not posted any itineraries yet.</div>
              )}
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <TailSpin
                height="80"
                width="80"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function ItineraryForm() {
  // const { navigate } = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    setPdf(acceptedFiles[0]);
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  const { auth } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [imgError, setImgError] = useState("");
  useEffect(() => {}, [error]);
  const validationErrors = (value, n) => {
    if (!value) {
      setError((prev) => {
        // prev[n] = `Please fill ${n}`;

        return "Please fill all the form fields";
      });

      return false;
    }
    setError("");
    // setError((prev) => ({ ...prev, n: "Filled" }));
    return true;
  };
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState("");
  const [pdf, setPdf] = useState(null);
  const [img, setImg] = useState([]);
  const [location, setLocation] = useState("");
  const [days, setDays] = useState(null);
  const [price, setPrice] = useState(null);
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [economy, setEconomy] = useState(null);
  const imgValidation = () => {
    if (img.length === 0) {
      setImgError("Please add atleast one image");
      return false;
    }
    setImgError("");
    return true;
  };
  const validation = () => {
    let errs = 0;
    if (!validationErrors(title, "title")) errs += 1;
    if (!validationErrors(pdf, "pdf")) errs += 1;
    if (!validationErrors(location, "location")) errs += 1;
    if (!validationErrors(days, "days")) errs += 1;
    if (!validationErrors(price, "price")) errs += 1;
    if (!validationErrors(description, "description")) errs += 1;
    if (!validationErrors(economy, "economy")) errs += 1;
    return errs;
  };
  const submitRequest = async () => {
    setSubmitting(true);
    var fdata = new FormData();
    if (validation() || !imgValidation()) {
      imgValidation();
      if (validation()) setError("Please fill all the fields");
      setSubmitting(false);
      window.scrollTo(0, 0);

      return 0;
    }
    fdata.append("title", title);
    fdata.append("location", location);
    fdata.append("days", days);
    fdata.append("price", price);
    fdata.append("description", description);
    fdata.append("tags", JSON.stringify(tags));
    fdata.append("cost", economy);
    // fdata.append("files", img);
    //  fdata.append("files")
    img.forEach((i) => {
      fdata.append("files", i);
      // console.log(i);
    });
    fdata.append("pdf", pdf);
    // console.log(fdata);
    try {
      const res = await axios({
        method: "POST",
        url: "itinerary/create/",
        data: fdata,

        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.jwt}`,
        },
      });

      if ((res.status = 200)) {
        setSuccess(true);
      }
    } catch (errors) {
      setSubmitting(false);
      setError("There was some error while submiting");
    }
  };

  if (success) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        Thank you! Your submission will be reviewed.
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="text-h4 text-blue font-bold self-center">
        Manage your itineraries
      </div>

      <div className="self-center">
        Add and manage the itineraries you have listed
      </div>
      {submitting ? (
        <div className="flex w-full justify-center items-center">
          <TailSpin
            height="80"
            width="80"
            color="blue"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="bg-white shadow-custom p-20">
            <form>
              <div className="flex flex-col gap-4">
                <div className="text-blue text-h6 font-bold">
                  1. Itineraries Details
                </div>
                <div className="text-red text-lg">{error || imgError}</div>
                <Input placeholder="Title" state={title} setState={setTitle} />
                <Location state={location} setState={setLocation} />
                <Input
                  placeholder="Days"
                  type="number"
                  state={days}
                  setState={setDays}
                />
                <div className="py-[2px] px-[8px] my-4">
                  <div className="text-lg text-grey my-4">Tags</div>

                  {[
                    "International",
                    "USA",
                    "USA Camping",
                    "USA Road Trips",
                    "Disney",
                    "Family Travel",
                    "Weekend Trips",
                  ].map((tagCheck, i) => (
                    <Checkbox
                      label={tagCheck}
                      key={i}
                      onChange={() => {
                        if (tags.includes(i + 1)) {
                          setTags((prevTags) => prevTags.filter((t) => t));
                        } else {
                          setTags((prevTags) => [...prevTags, i + 1]);
                        }
                      }}
                      // checked={}
                    />
                  ))}
                </div>

                <div className="py-[2px] px-[8px] my-4">
                  <div className="text-lg text-grey my-4">Cost of Trip</div>
                  <div className="flex gap-6">
                    {["Economy", "Moderate", "Luxury"].map((econ, i) => (
                      <Checkbox
                        label={econ}
                        key={i}
                        checked={economy === econ}
                        onChange={() => {
                          setEconomy(econ);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <Pricing
                  placeholder="Price"
                  type="number"
                  state={price}
                  setState={setPrice}
                />
                <textarea
                  placeholder="Description"
                  className="border-grey text-lg text-blue teborder-b-[1px] w-full py-[2px] px-[8px] "
                  rows="4"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex">
                  <div className="text-blue text-h6 font-bold">2.&nbsp;</div>
                  <div>
                    <div className="text-blue text-h6 font-bold">Photos</div>
                    <div className="text-blue text-lg font-bold">
                      Upload atleast one high quality photo of your itinerary.
                    </div>
                    <div className="text-red text-lg">{imgError}</div>
                    <div className="w-[80%] my-8 flex flex-wrap gap-14 justify-center">
                      <Upload
                        featured="true"
                        name="1"
                        setState={setImg}
                        index={0}
                        err={setImgError}
                      />
                      <Upload
                        name="2"
                        setState={setImg}
                        index={1}
                        err={setImgError}
                      />
                      <Upload
                        name="3"
                        setState={setImg}
                        index={2}
                        err={setImgError}
                      />
                      <Upload
                        name="4"
                        setState={setImg}
                        index={3}
                        err={setImgError}
                      />
                      <Upload
                        name="5"
                        setState={setImg}
                        index={4}
                        err={setImgError}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex">
                  <div className="text-blue text-h6 font-bold">3.&nbsp;</div>
                  <div>
                    <div className="text-blue text-h6 font-bold">
                      Upload Itinerary
                    </div>
                    <div className="text-blue text-lg font-bold">
                      Upload the Itinerary in .pdf or .doc file format which
                      will be only accessible after purchasing it.
                    </div>
                  </div>
                </div>
                <div
                  {...getRootProps({
                    className:
                      "dropzone bg-bggrey border w-full h-[180px] border-dashed flex flex-col justify-center items-center",
                  })}
                >
                  <input {...getInputProps()} />
                  <img src="./img/dropzone-icon.svg" alt="" />
                  <p className="text-lg font-bold">
                    Drag and drop or browse your files
                  </p>
                  <p>{pdf ? pdf.name : ""}</p>
                </div>
              </div>
              <div className="flex w-full flex-col my-6">
                <Button
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    submitRequest();
                  }}
                >
                  Submit Request
                </Button>
              </div>
            </form>
            <p className="mt-16 mb-10 text-center">
              By continuning, you agree to Privacy policy.
            </p>
          </div>
        </>
      )}
    </div>
  );
}

function DropZone() {
  return <></>;
}

function Upload({ name, setState, index, featured, err }) {
  const inputEl = useRef(null);
  const [heif, setHeif] = useState(0);
  const [img, setImg] = useState(null);

  const removeHandler = () => {
    inputEl.current.value = "";
    setState((prev) => {
      const newArray = prev.filter((_, i) => i !== index);
      return newArray;
    });
    setImg(null);
  };

  const changeHandler = async (event) => {
    const imageFile = event.target.files[0];
    if (event.target.files[0].size > 3500000) {
      err(
        "File size is too large. Please make sure the image size is less than 3MB."
      );
      return;
    }
    var newFile;

    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      setHeif(1);
      newFile = await heic2any({
        blob: imageFile,
        toType: "image/jpeg",
        quality: 1,
      });
      setHeif(0);
    } catch (error) {
      // console.log(error);
      newFile = imageFile;
      setHeif(0);
    }

    try {
      const compressedFile = await imageCompression(newFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      err("");

      setState((prev) => [
        ...prev.slice(0, index),
        compressedFile,
        ...prev.slice(index + 1),
      ]);

      setImg(URL.createObjectURL(compressedFile));
    } catch (error) {
      err(
        "The file type is unsuported or corrupted. Please make sure the file is a valid image type."
      );
    }
  };

  return (
    <div
      className={`h-[200px] w-[200px] shadow-custom relative ${
        featured ? "border-[6px] border-blue border-opacity-0 " : ""
      }custom-background-style`}
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <input
        ref={inputEl}
        type="file"
        name={name}
        onChange={changeHandler}
        style={{ display: "none" }}
        accept="image/*"
      />
      <a onClick={removeHandler}>
        <div className="cursor-pointer absolute right-0 top-0 h-[30px] w-[30px] bg-red opacity-[0.6] rounded-full flex justify-center items-center">
          <i className=" fa fa-x text-white"></i>
        </div>
      </a>
      <a onClick={() => inputEl.current.click()}>
        <div className="cursor-pointer absolute right-0 bottom-0 m-2 h-[40px] w-[40px] bg-blue rounded-full flex justify-center items-center">
          <i className=" fa fa-plus text-white"></i>
        </div>
      </a>
    </div>
  );
}
function Input(props) {
  const { state, setState } = props;
  return (
    <input
      className="outline-0 min-h-[38px] py-[2px] px-[8px] w-full text-lg text-blue bg-white border-grey border-b-[1px] "
      {...props}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
    />
  );
}
function Pricing(props) {
  const { state, setState } = props;
  return (
    <div className="relative">
      <input
        className="outline-0 min-h-[38px] py-[2px] px-[8px] w-full text-lg text-blue bg-white border-grey border-b-[1px] "
        {...props}
        value={state}
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <i className="fa fa-dollar absolute right-0 bottom-0 text-grey p-2"></i>
    </div>
  );
}

function Location(props) {
  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState(null);
  const [city, setCity] = useState(null);
  const { state, setState } = props;
  const search = function (t) {
    let res = [];
    data.forEach((e) => {
      if (res.length > 3) {
        return;
      }
      let curren = res.length;
      e.states.forEach((v) => {
        if (res.length > 3) {
          return;
        }
        let cur = res.length;
        v.cities.forEach((c) => {
          if (res.length > 3) {
            return;
          }
          if (c.toLowerCase().includes(t.toLowerCase()))
            res.push({
              value: `${c},${v.name},${e.name}`,
              label: `${c}, ${v.name}, ${e.name}`,
            });
        });
        if (
          res.length == cur &&
          v.name.toLowerCase().includes(t.toLowerCase())
        ) {
          res.push({
            value: `${v.cities[0] ? v.cities[0] + "," : ""}${v.name},${e.name}`,
            label: `${v.cities[0] ? v.cities[0] + "," : ""} ${v.name}, ${
              e.name
            }`,
          });
        }
      });
      if (
        res.length == curren &&
        e.name.toLowerCase().includes(t.toLowerCase())
      )
        res.push({
          value: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
          label: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
        });
    });
    setOptions(res);
  };
  const searchCountry = function (t) {
    let res = [];
    data.forEach((e, i) => {
      if (e.name.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: i, label: `${e.name}` });
      }
    });
    setOptions(res);
  };
  const searchState = function (t) {
    let res = [];

    data[country.value].states.forEach((e, i) => {
      if (e.name.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: i, label: `${e.name}` });
      }
    });
    setOptions(res);
  };
  const searchCities = function (t) {
    let res = [];

    data[country.value].states[states.value].cities.forEach((e) => {
      if (e.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: e, label: e });
      }
    });
    setOptions(res);
  };
  const colourStyles = {
    container: (styles) => ({ ...styles }),
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      borderWidth: isFocused ? 0 : "0px 0px 1px",
      borderColor: "var(--theme-grey)",
      boxShadow: "none",

      minWidth: "12rem",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "var(--theme-blue)";
      return {
        ...styles,
        fontSize: "1.125em",
        backgroundColor: "fff",
        color: color,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: "var(--theme-blue)",
        fontSize: "1.125em",
      };
    },
    placeholder: (styles, { data }) => {
      return {
        ...styles,
        color: "var(--theme-grey)",
        fontSize: "1.125em",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: "var(--theme-grey)",
      };
    },
  };
  useEffect(() => {}, []);
  const handleChangeCountry = (v) => {
    setCountry(v);
    setStates(null);
    setCity(null);
    setState(`${v.label}`);
    // console.log(state);
  };
  const handleChangeStates = (v) => {
    setStates(v);
    setCity(null);
    setState(`${v.label},${country.label}`);
    // console.log(state);
  };
  const handleChangeCity = (v) => {
    setCity(v);
    setState(`${v.label}, ${states.label}, ${country.label}`);
    // console.log(state);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <Select
        styles={colourStyles}
        options={options}
        placeholder={"Country"}
        onChange={handleChangeCountry}
        onFocus={() => {
          searchCountry("");
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchCountry(e);
        }}
        value={country}
      />
      <Select
        styles={colourStyles}
        options={options}
        placeholder={"State"}
        onChange={handleChangeStates}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchState(e);
        }}
        onFocus={() => {
          setOptions([]);
        }}
        value={states}
        isDisabled={country ? false : true}
      />
      <Select
        styles={colourStyles}
        options={options}
        placeholder={"City"}
        onChange={handleChangeCity}
        onFocus={() => {
          setOptions([]);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchCities(e);
        }}
        value={city}
        isDisabled={states ? false : true}
      />
    </div>
  );
}
