import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
// const img = "./img/blog.png";
export default function Card({ img, title, text, date, id }) {
  return (
    <div
      className=" overflow-hidden flex flex-col justify-end gap-10 w-[290px] h-[475px] md:w-[420px] md:h-[600px]  shadow-custom"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="bg-white h-[50%] w-full custom-clip ">
        <div className="ml-[36%] flex flex-col gap-2 justify-end  mt-10 mr-6">
          <div>
            <span className="text-sm text-grey">
              {moment(Date(date)).format("YYYY-MM-DD")}
            </span>
          </div>
          <div>
            <span className="text-blue leading-6 text-xl font-semibold">
              {title}
            </span>
          </div>
          <div>
            <span className="text-blue text-sm">
              {text.substring(0, 100) + "...."}
            </span>
          </div>
          <div className="w-full text-right">
            <a href="#">
              <span className="text-blue text-sm font-semibold">
                <Link to={`/single-blog/${id}`}>Read More</Link>
              </span>
              <i className="fa fa-arrow-right leading p-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
