import React, { useState } from "react";
import Button from "../components/form-kit/button";
import Page from "../layout/page";
import Section from "../layout/section";
import axios from "../api/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function ForgotPassword() {
  const { id } = useParams();
  const { auth, setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  useEffect(() => {
    // console.log(auth);
    if (auth.jwt) {
      navigate("/accounts");
    }
  }, []);
  const handeSubmit = async () => {
    if (password === "" || passwordConfirm === "") {
      setErr("Fill up both fields");
      return 0;
    }
    if (!(password === passwordConfirm)) {
      setErr("Passwords dont match");
      return 0;
    }
    setLoading(true);
    try {
      const res = await axios.post("/auth/reset-password", {
        password: password,
        passwordConfirmation: passwordConfirm,
        code: id,
      });
      setAuth((prev) => ({ ...prev, jwt: res.data.jwt }));

      navigate("/accounts");
    } catch (error) {
      setLoading(false);
      setErr(error.response.data.error.message);
    }
  };
  return (
    <Page>
      <Helmet>
        <title>Forgot Password-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex justify-center items-center">
          <div className="shadow-custom w-[40%] my-8 p-10 relative">
            {loading && (
              <div className="bg-white opacity-[.6] absolute w-full h-full top-0 left-0"></div>
            )}
            <div className="text-h5 font-bold text-blue text-center">
              Change Password
            </div>
            <form className="my-2 flex flex-col gap-10">
              <div className="text-red text-sm">{err}</div>
              <Input
                placeholder="Password"
                type="password"
                value={password}
                function={setPassword}
              />
              <Input
                placeholder="Confirm Password"
                type="password"
                value={passwordConfirm}
                function={setPasswordConfirm}
              />

              <Button
                primary="true"
                onClick={(e) => {
                  e.preventDefault();
                  handeSubmit();
                }}
              >
                Confirm
              </Button>
            </form>
          </div>
        </div>
      </Section>
    </Page>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-lg text-blue border-grey border-b-[1px] bg-[#00000000]"
      onChange={(e) => {
        props.function(e.target.value);
      }}
      {...props}
    />
  );
}
