import Page from "../layout/page";
import Section from "../layout/section";
import React, { useState } from "react";
import Profile from "./account/profile";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import axios from "../api/axios";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";

export default function Accounts() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userdata, setData] = useState(null);
  const { auth, setAuth } = useContext(AuthContext);
  // console.log(auth)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get("/users/me?populate=%2A", {
          headers: {
            Authorization: `Bearer ${auth.jwt}`,
          },
        });

        setData(data.data);
      } catch (error) {
        if (error.response.data.error.status == 401) {
          localStorage.removeItem("TSS_APP_JWT");
          localStorage.removeItem("TSS__APP__JWT");
          setAuth((prev) => ({ ...prev, jwt: null }));
          navigate("/sign-in", { replace: true });
        }
      }
    };
    if (!auth.jwt) {
      navigate("/sign-in", { replace: true });
    } else {
      localStorage.setItem("TSS__APP__JWT", JSON.stringify(auth.jwt));
      fetchData();
      setLoading(false);
    }
  }, []);
  return (
    <Page>
      <Helmet>
        <title>Accounts-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex flex-col md:flex-row justify-around items-center md:items-start">
          <div className="flex md:flex-col gap-2 md:gap-5  list-none text-sm md:text-lg  text-blue font-semibold md:w-[20%] accounts-shadow">
            <NavLink
              to="profile"
              className={({ isActive }) =>
                isActive ? "bg-blue text-white" : ""
              }
            >
              <li className="p-4 ">PROFILE </li>
            </NavLink>
            <NavLink
              to="my-itineraries"
              className={({ isActive }) =>
                isActive ? "bg-blue text-white" : ""
              }
            >
              <li className="p-4 ">MY SHARED ITINERARIES</li>
            </NavLink>
            <NavLink
              to="purchased"
              className={({ isActive }) =>
                isActive ? "bg-blue text-white" : ""
              }
            >
              <li className="p-4 ">PURCHASED ITINERARIES </li>
            </NavLink>
            <NavLink
              to="payment"
              className={({ isActive }) =>
                isActive ? "bg-blue text-white" : ""
              }
            >
              <li className="p-4 ">PAYMENT METHOD</li>
            </NavLink>
          </div>
          <div className="relative w-[100%] md:w-[80%] p-14">
            {!userdata ? (
              <div className="w-full h-full flex justify-center items-center">
                <TailSpin
                  height="80"
                  width="80"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <Outlet context={{ data: userdata, jwt: auth.jwt }} />
            )}
          </div>
        </div>
      </Section>
    </Page>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-sm text-blue bg-white border-grey border-b-[1px]"
      {...props}
    />
  );
}
