const baseTheme = {
  "--theme-white": "#ffff",
  "--theme-blue": "#00396C",
  "--theme-yellow": "#FEDF7A",
  "--theme-black": "#000000",
  "--theme-grey": "#464e4e",
  "--theme-darkgrey": "#757575",
  "--theme-bgcolor": "#FFFEF8",
  "--theme-bgblue": "#F0F0F0",
  "--theme-bggrey": "#D9D9D9",
  "--theme-reviewblue": "#E3F2FD",
  "--theme-textgrey": "#8E8E8E",
};
export default baseTheme;
