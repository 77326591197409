import React, { useState } from "react";
import Button from "../components/form-kit/button";
import Page from "../layout/page";
import Section from "../layout/section";

export default function Confirmation() {
  return (
    <Page>
      <Section>
        <div className="flex justify-center items-center w-full h-[65vh]">
          <div>Please Check your Email for Confirmation</div>
        </div>
      </Section>
    </Page>
  );
}
