import React from "react";
import AccordionSection from "../components/faqAccordion";
import Section from "../layout/section";
import Page from "../layout/page";
import { Helmet } from "react-helmet";

export default function faq() {
  return (
    <Page>
      <Helmet>
        <title>FAQs-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                Frequently asked questions
              </span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                How can we help you?
              </span>
            </div>
          </div>
          <div className="my-8 w-[80%]">
            <AccordionSection />
          </div>
        </div>
      </Section>
    </Page>
  );
}
