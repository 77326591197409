import React, { Fragment, useState } from "react";
import { questions } from "../utils/questions";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
export default function AccordionSection() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Fragment>
      {questions.map((data, i) => {
        return (
          <Accordion
            key={i}
            open={open === i + 1}
            onClick={() => handleOpen(i + 1)}
            icon={<Icon id={i + 1} open={open} />}
            className="hover:scale-[1.01] border-b-[1px] border-grey"
          >
            <AccordionHeader className="text-blue font-bold">
              {data.ques}
            </AccordionHeader>
            <AccordionBody  dangerouslySetInnerHTML={{__html: data.ans}}></AccordionBody>
          </Accordion>
        );
      })}
    </Fragment>
  );
}

function Icon({ id, open }) {
  return (
    <div
      className={`border w-5 border-3 rounded-full ${
        id === open ? "" : "border-darkgrey text-darkgrey"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        {id === open ? (
          <path
            fill-rule="evenodd"
            d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        ) : (
          <path
            fill-rule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clip-rule="evenodd"
          ></path>
        )}
      </svg>
    </div>
  );
}
