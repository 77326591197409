// import Navbar from "../layout/navbar";
// import Footer from "../layout/footer";
import Section from "../layout/section";
import Button from "../components/form-kit/button";
import { useNavigate } from "react-router-dom";
import AccordionSection from "../components/faqAccordion";
import Page from "../layout/page";
import { Helmet } from "react-helmet";

export default function HowItWorks() {
  const navigate = useNavigate();
  return (
    <Page>
      <Helmet>
        <title>How it Works-Travel Swap Shop</title>
        <link rel="canonical" href="https://travelswapshop.com/how-it-works" />
      </Helmet>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">How it Works</span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                Share and search pre-made itineraries by fellow travellers for
                your next adventure!
              </span>
            </div>
          </div>
          <div className="my-8">
            <div className="flex flex-col md:flex-row justify-center items-center">
              <HeroElement
                title="Create an account"
                img="./img/create_account.png"
                text="Create Join Travel Swap Shop and have access to both share and purchase travel itineraries."
              />
              <img
                src="./img/arrow.svg"
                className="hidden md:block mb-20"
                alt=""
              />
              <HeroElement
                title="Set a price for your itinerary"
                img="./img/price.png"
                text={`<div>
                    Some things to consider when determining the price:
                    <ul class="ml-5">
                      <li style="list-style-type: circle">
                        the time you spent creating the itinerary
                      </li>
                       <li style="list-style-type: circle">
                        the length of the itinerary
                      </li>
                       <li style="list-style-type: circle">
                        how detailed the itinerary is
                      </li>
                    </ul>
                  </div>`}
              />
              <img
                src="./img/arrow.svg"
                className="hidden md:block mb-20"
                alt=""
              />
              <HeroElement
                title="Upload your itinerary as an Excel or Word document"
                img="./img/upload.png"
                text={`If you're like me, your itineraries take many different formats. <a href="./TSS What makes a great itinerary.docx" class="text-blue">Click here</a> to use our simple format for your past and future itineararies.`}
              />
            </div>
          </div>
          <div className="my-6">
            <Button
              onClick={() => {
                navigate("/sign-up");
              }}
              primary="true"
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </Section>
      <Section className="bg-bgblue hidden">
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                Why share your itineraries?{" "}
              </span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                Viverra vitae congue eu consequat. Mauris rhoncus aenean vel
                elit scelerisque mauris
              </span>
            </div>
          </div>
          <div className="flex flex-wrap justify-center w-[85%] gap-8 my-8">
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/upload.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/price.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/create_account.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/upload.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
          </div>
          <div className="my-6">
            <Button
              // onClick={() => {
              //   navigate("how-it-works");
              // }}
              primary="true"
            >
              Become a Seller
            </Button>
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                Frequently asked questions
              </span>
            </div>
          </div>
          <div className="my-8 w-[80%]">
            <AccordionSection />
          </div>
        </div>
      </Section>
    </Page>
  );
}

function HeroElement({ title, text, img }) {
  return (
    <div className="w-[70%] my-4 md:my-0 md:w-1/5">
      <img src={img} className="mx-auto" alt="" />
      <p className="text-center text-lg text-blue font-bold">{title}</p>
      <p className="text-center" dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  );
}

function SellLayout({ title, text, img }) {
  return (
    <div className="flex gap-2 max-w-[450px] items-center">
      <img src={img} width={"20%"} alt="title" />
      <div>
        <div className="text-blue text-md font-bold">{title}</div>
        <span className="text-grey text-sm font-semibold w-[80%]">{text}</span>
      </div>
    </div>
  );
}
