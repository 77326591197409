import React, { useState } from "react";
import axios from "../api/axios";
import Button from "../components/form-kit/button";
import Page from "../layout/page";
import { Link } from "react-router-dom";
import Section from "../layout/section";
export default function ContactUs() {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeHandler = async () => {};
  const handleSubmit = async () => {
    let formData = new FormData();

    // console.log(name, details, subject);
    setError("");
    if (name == "" || details == "" || subject == "") {
      setError("Please fill all the fields");
      return;
    }

    try {
      setLoading(true);
      const res = await axios.post("/contacts", {
        data: { name: name, description: details, subject: subject },
      });
      if (res.status == 200) setSuccess(true);
      else setSuccess(false);
      setLoading(false);
    } catch (error) {
      console.log(error.data.error.message);
    }
  };

  return (
    <Page>
      <Section className="my-16 px-8">
        <div className="flex justify-around">
          <div className="w-[40%]">
            <img
              src="./img/contact-us-hero.svg"
              className="mx-auto"
              alt={"Contact us graphics"}
            />
            <span className="text-h4 text-blue font-bold">
              Thanks for your interest in Travel Swap Shop !
            </span>
            <p>
              We hope you found an itinerary you can use for your next
              adventure!
            </p>
          </div>
          <div className="relative w-[31%] my-6">
            <form className="relative flex bg-white flex-col p-8 gap-8 z-30">
              <div className="text-red ">{error}</div>
              {success && (
                <div className="text-blue text-center">
                  {"Your message has been sent"}
                </div>
              )}
              <Input
                placeholder="Full Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <Input
                placeholder="Subject"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                value={subject}
              />
              <textarea
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
                value={details}
                placeholder="Message"
                className="border-grey border-b-[1px] w-full"
                rows="4"
              ></textarea>

              <Button
                primary
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Submit Request
              </Button>
            </form>

            <div className="absolute bottom-0 right-0 left-0 mx-auto z-30">
              <p className="text-center text-sm p-4">
                By continuning, you agree to{" "}
                <Link to="/privacy-policies" className="text-blue underline">
                  Privacy policy.
                </Link>
              </p>
            </div>
            {loading && (
              <div className="absolute bg-white w-full h-full shadow-custom  opacity-[0.4] z-40 top-0"></div>
            )}
            <div className="absolute bg-white w-full h-full shadow-custom  z-20 top-0"></div>
            <div className="absolute bg-yellow w-full h-full z-10 top-6 -right-6 shadow-custom "></div>
          </div>
        </div>
      </Section>
    </Page>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-sm text-blue bg-white border-grey border-b-[1px]"
      {...props}
    />
  );
}
