import React, { useState } from "react";
import Button from "../components/form-kit/button";
import Page from "../layout/page";
import Section from "../layout/section";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { useEffect } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";

export default function SignUp() {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");

  useEffect(() => {
    // console.log(auth);
    if (auth.jwt) {
      navigate("/accounts", { replace: true });
    }
  }, []);
  const handeSubmit = async () => {
    if (username === "" || password === "" || email === "") {
      setErr("Please fill all the fields");
      return 0;
    }
    setLoading(true);
    // const res = await axios.post("/auth/local/register", {
    //   username: username,
    //   password: password,
    //   email: email,
    // });
    axios
      .post("/auth/local/register", {
        username: username,
        password: password,
        email: email,
      })
      .then(function (response) {
        navigate("/confirmation");
      })
      .catch(function (error) {
        setLoading(false);
        setErr(error.response.data.error.message);
      });
    // if (res.request.status == 200) {
    //
    // }
  };
  return (
    <Page>
      <Helmet>
        <title>Sign Up-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex justify-center items-center">
          <div className="shadow-custom w[100%] md:w-[40%] my-8 p-10 relative">
            {loading && (
              <div className="bg-white opacity-[.6] absolute w-full h-full top-0 left-0"></div>
            )}
            <div className="text-h5 font-bold text-blue text-center">
              Create Account
            </div>
            <div className="text-red">{err}</div>
            <form className="my-2 flex flex-col gap-6">
              <Input
                placeholder="Name"
                value={username}
                function={setUsername}
              />
              <Input placeholder="Email" value={email} function={setEmail} />
              <Input
                placeholder="Password"
                type="password"
                value={password}
                function={setPassword}
              />

              <Button
                primary="true"
                onClick={(e) => {
                  e.preventDefault();
                  handeSubmit();
                }}
              >
                Sign Up
              </Button>
              <div className="text-blue text-lg font-bold flex justify-center items-center gap-2">
                <div className="w-8 h-[.5px] border-blue border-[.5px]"></div>
                <Link to="/sign-in">Or Sign In</Link>
                <div className="w-8 h-[.5px] border-blue border-[.5px]"></div>
              </div>
            </form>

            <div className="mt-16 mb-10 text-center">
              By continuning, you agree to Privacy policy.
            </div>
          </div>
        </div>
      </Section>
    </Page>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-lg text-blue border-grey border-b-[1px] bg-[#00000000]"
      onChange={(e) => {
        props.function(e.target.value);
        // console.log(e.target.value);
      }}
      {...props}
    />
  );
}
