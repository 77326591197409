import React from "react";

export default function Button(props) {
  const createClass = () => {
    let base =
      "text-lg font-bold py-1 border-blue px-5 border-blue rounded-full border-[1px] shadow-filter-buttons ";
    if (props.active) return base + "bg-blue text-white border-blue";
    else {
      return base + "hover:bg-blue hover:text-white border-blue ext-blue";
    }
  };

  return (
    <button {...props} className={createClass()}>
      {props.children}
    </button>
  );
}
