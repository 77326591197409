import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilterButton from "./filter/filterButton";
import Card from "./card";
import axios from "../api/axios";
import { Star } from "./card";
import Checkbox from "./filter/checkbox";
import ReactPaginate from "react-paginate";
import qs from "qs";

const options = [
  { value: 0, label: "1-3 Days",days:[1,3] },
  { value: 1, label: "3-7 Days" ,days:[3,7]},
  { value: 2, label: "7-10 Days",days:[7,10] },
  { value: 3, label: "10+ Days",days:[10,999] },
];
const costs = [
  { value: [1, 50], label: "Economy" },
  { value: [50, 1000], label: "Moderate" },
  { value: [1000, 99999], label: "Luxury" },
];

export default function BlogList({ pageSize, query }) {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const [data, setData] = useState(null);
  const [cost, setCost] = useState([]);
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState([]);
  const [active, setActive] = useState(0);
  const [request, setRequest] = useState(query);
  const [days,setDays] = useState([]);
  
  const isActive = (n) => {
    if (active === n) return true;
    return false;
  };
  const getData = async (query) => {
    try {
      setLoading(true);
      // let res = await axios.get(
      //   `/itineraries?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=images&populate=author`
      // );
      let res = await axios.get(`itineraries?${query}`);
      setData(res.data);
      // console.log(res.data);
      setLoading(false);
    } catch (errors) {
      console.log(errors);
    }
  };
  const getTags = async () => {
    try {
      let res = await axios.get("/tags");
      setTags(res.data.data);
      // console.log(res.data);
    } catch (errors) {}
  };
  const getDays = ()=>{
    let temp = [0,9999];
    if(days.includes(0)){
      temp[0] = 1;temp[1]=3;
    }
    if(days.includes(1)){
      temp[3] = 1;temp[1]=3;
    }
  }
  useEffect(() => {
    if (tags.length == 0) getTags();
    // console.log(query);
   
    // if (!data) {
    getData(
      qs.stringify(
        {
          ...query,
          filters: {
            ...query.filters,
          
          

           
            ...request.filters,
            ...(days.length>0?{
              days: {
                $gt: options[Math.min.apply(null, days)].days[0],
                $lt: options[Math.max.apply(null, days)].days[1]
              },
            }:{}),
            ...(cost?{ cost: {
              $in:cost
            },}:{}),
            ...(rating ? { rating: { $in: rating } } : {}),
          },
          pagination: { page: page },
          populate: ["images", "author"],
        },
        { encodeValuesOnly: true }
      )
    );
    // }
  }, [query, page, request, rating, days, cost]);
  if (!tags) {
    return <></>;
  }
  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-center w-[70%] gap-4 mx-auto">
        <FilterButton
          active={isActive(0)}
          onClick={() => {
            setActive(0);
            setRequest((prev) => ({
              ...prev,
              filters: {
                ...request.filters,
                tags: {},
              },
            }));
          }}
        >
          All
        </FilterButton>

        {tags.map((e, i) => (
          <FilterButton
            key={i}
            active={isActive(i + 1)}
            onClick={() => {
              setActive(i + 1);
              setRequest((prev) => ({
                ...prev,
                filters: {
                  ...request.filters,
                  tags: {
                    id: {
                      $eq: e.id,
                    },
                  },
                },
              }));
            }}
          >
            {e.attributes.Name}
          </FilterButton>
        ))}
      </div>
      <div className="flex flex-col md:flex-row w-[100%] mx-auto px-10 my-10">
        <div className="w-[80%] m-4 md:m-0 md:w-[20%]">
          <div className="flex flex-col gap-4 text-blue font-semibold">
            <span className="text-xl">Filter</span>
            <div className="h-1 w-full border-grey border-[1px] rounded"></div>
            <span className="text-sm">Ratings</span>
            <div className="flex flex-col gap-2">
              {[1, 2, 3, 4, 5].map((f,i) => (
                <div className="flex" key={i} >
                  <Checkbox
                    onChange={() => {
                      if (rating.includes(f)) {
                        setRating((prev) =>
                          [...prev].filter((item) => item !== f)
                        );
                        return;
                      }
                      setRating((prev) => [...prev, f]);
                    }}
                  />
                  <Star rating={f} />
                </div>
              ))}
            </div>
            <div className="h-1 w-full border-grey border-[1px] rounded"></div>
            <span className="text-sm">Cost of Trip</span>
            <div className="">
              {costs.map((f,i) => (
                <Checkbox
                  key={i}
                  label={f.label}
                  checked={cost.includes(f.label)}
                  onChange={() => {
                    // if ()
                    //   setCost([0, 99999]);
                    // else {
                    //   setCost(f.value);
                    // }
                    if(cost.includes(f.label)){
                      setCost(prev=>(prev.filter(c=>c!==f.label)));
                      return;
                    }
                    setCost(prev=>([...prev,f.label]))
                  }}
                />
              ))}
            </div>
            <div className="h-1 w-full border-grey border-[1px] rounded"></div>
            <span className="text-sm">Trip Duration</span>
            <div className="">
              {options.map((f,i) => (
                <Checkbox
                  key={i}
                  label={f.label}
                  onChange={() => {
                    if (days.includes(f.value))
                      setDays(prev=>(prev.filter(c=>c!==f.value)));
                    else {
                      setDays(prev=>([...prev,f.value]));
                    }
                  }}
                  checked={days.includes(f.value)}
                />
              ))}
            </div>
            <div className="h-1 w-full border-grey border-[1px] rounded"></div>
          </div>
        </div>
        <div className="w-[100%] md:w-[80%] px-4 flex flex-wrap justify-center gap-6 relative">
          {loading && (
            <div className="w-full h-full bg-white absolute opacity-[0.4]"></div>
          )}
          {data ? (
            data.data.map((e,i) => (
              <Link to={`/single-itinerary/${e.id}`} key={i}>
                <Card
                  title={e.title}
                  location={e.location}
                  price={e.price}
                  days={e.days}
                  img={e.images[0].url}
                  author={e.author.username}
                  score={e.rating}
                />
              </Link>
            ))
          ) : (
            <></>
          )}
          {data ? data.data.length == 0 && "Cannot find results" : <></>}
        </div>
      </div>
      <div className="flex items-center justify-end w-[90%] my-10 mx-auto">
        {data && (
          <ReactPaginate
            breakLabel="...."
            previousLabel="<"
            nextLabel=">"
            onPageChange={(e) => {
              setPage(e.selected + 1);
            }}
            pageRangeDisplayed={4}
            pageCount={data.pagination.pageCount}
            // pageCount={4}
            renderOnZeroPageCount={null}
            containerClassName="flex gap-2 text-sm"
            activeLinkClassName="bg-blue text-white"
            pageLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
            nextLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
            previousLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
          />
        )}
      </div>
    </div>
  );
}
