import React from "react";
import Page from "../layout/page";
import Section from "../layout/section";
import { Helmet } from "react-helmet";

export default function NotFound() {
  return (
    <Page>
      <Helmet>
        <title>404 Error-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex flex-col justify-center items-center min-h-[60vh]">
          <div className="text-h1 text-blue">404</div>
          <div className="text-h6">
            The page you are looking for is not available.
          </div>
        </div>
      </Section>
    </Page>
  );
}
