import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="py-8 px-14 bg-bggrey ">
      <div className="max-w-[1600px] mx-auto flex justify-between">
        <div className="w-[60%] md:w-[25%] flex flex-col gap-3">
        <Link to="/">
          <img
            src="./img/logo_transparent.png"
            className="w-[188px] h-[40px]"
          />
          </Link>
          <p className="text-darkgrey text-sm">
          Save time and energy by searching for a pre-made itinerary you can use or adapt and share itineraries you have already created! 
          </p>
          <div className="flex gap-2 text-xs text-white">
            <i className="fa-brands fa-facebook-f p-2 bg-black rounded" />
            <i className="fa-brands fa-instagram p-2 bg-black rounded" />
            <i className="fa-brands fa-twitter p-2 bg-black rounded" />
          </div>
        </div>
        <div className="flex flex-col justify-between text-right">
          <Link to="/about-us" className="underline">
            About Us
          </Link>
          <Link to="/contact-us" className="underline">
            Contact Us
          </Link>
          <Link to="/privacy-policies" className="underline">
            Privacy Policies
          </Link>
          <Link to="/blogs" className="underline">
            Blogs
          </Link>
          <Link to="/faq" className="underline">
            FAQ
          </Link>
        </div>
      </div>
      <hr className="text-darkgrey my-4 max-w-[1600px] mx-auto" />
      <div>
        <p className="text-xs text-center">
          All material herein © 2023 Travel Swap Shop. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}
