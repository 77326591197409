import React, { useState } from "react";
import Button from "../components/form-kit/button";
import Page from "../layout/page";
import Section from "../layout/section";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function SignIn() {
  const { auth, setAuth } = useContext(AuthContext);
  const [forgot, setForgot] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [resetmsg, setResetMsg] = useState("");
  const [err, setErr] = useState("");
  useEffect(() => {
    if (auth.jwt) {
      navigate("/accounts", { replace: true });
    }
  }, []);
  const handeSubmit = async () => {
    if (username === "" || password === "") {
      setErr("Please fill all the fields");
      return 0;
    }
    setLoading(true);
    try {
      const res = await axios.post("/auth/local", {
        identifier: username,
        password: password,
      });

      setAuth((prev) => ({ ...prev, jwt: res.data.jwt }));

      navigate("/accounts", { replace: true });
    } catch (error) {
      setLoading(false);
      setErr(error.response.data.error.message);
    }
  };
  const handleReset = async () => {
    if (username === "") {
      setErr("Please input email");
      return 0;
    }
    setLoading(true);
    try {
      const res = await axios.post("/forget-password", {
        email: username,
      });
      setLoading(false);
      setResetMsg("Please check your email");
    } catch (error) {
      setLoading(false);
      setErr(error.response.data.error.details[0].messages[0].message);

      setResetMsg("");
    }
  };
  return (
    <Page>
      <Helmet>
        <title>Sign In-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex justify-center items-center">
          <div className="shadow-custom w-[100%] md:w-[40%] my-8 p-10 relative">
            {forgot ? (
              <i
                className="fa fa-arrow-left cursor-pointer"
                onClick={() => {
                  setForgot(false);
                }}
              ></i>
            ) : (
              <></>
            )}

            {loading && (
              <div className="bg-white opacity-[.6] absolute w-full h-full top-0 left-0"></div>
            )}
            <div className="text-h5 font-bold text-blue text-center">
              {forgot ? "Reset Password" : "Sign In"}
            </div>
            {forgot ? (
              <form className="my-2 flex flex-col gap-6">
                <div className="text-red text-sm">{err}</div>
                <Input
                  placeholder="Email"
                  value={username}
                  function={setUsername}
                />

                <Button
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleReset();
                  }}
                >
                  Send Email
                </Button>
                <div className="text-blue text-sm text-center">{resetmsg}</div>
              </form>
            ) : (
              <form className="my-2 flex flex-col gap-6">
                <div className="text-red text-sm">{err}</div>
                <Input
                  placeholder="Email"
                  value={username}
                  function={setUsername}
                />
                <Input
                  placeholder="Password"
                  type="password"
                  value={password}
                  function={setPassword}
                />
                <div
                  className="text-sm text-right p-2 text-blue underline cursor-pointer"
                  onClick={() => {
                    setForgot(true);
                  }}
                >
                  Forgot Password?
                </div>
                <Button
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handeSubmit();
                  }}
                >
                  Sign In
                </Button>
                <div className="text-blue text-lg font-bold flex justify-center items-center gap-2">
                  <div className="w-8 h-[.5px] border-blue border-[.5px]"></div>
                  <Link to="/sign-up">Or Sign Up</Link>
                  <div className="w-8 h-[.5px] border-blue border-[.5px]"></div>
                </div>
              </form>
            )}

            <div className="mt-16 mb-10 text-center">
              By continuning, you agree to Privacy policy.
            </div>
          </div>
        </div>
      </Section>
    </Page>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-lg text-blue border-grey border-b-[1px] bg-[#00000000]"
      onChange={(e) => {
        props.function(e.target.value);
      }}
      {...props}
    />
  );
}
