import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import Page from "../layout/page";
import Section from "../layout/section";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
export default function SingleBlog() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`blogs/${id}?populate=hero`);
        setData(res.data);
        // console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  if (!data) {
    return <></>;
  }
  return (
    <Page>
      <Helmet>
        <title>{data.data.attributes.title}-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="w-full flex flex-col items-center my-8">
          <img
            src={data.data.attributes.hero.data.attributes.url}
            className="my-6"
            alt="Hero featured"
          />

          <div className="max-w-[1200px]">
            <div className="text-lg text-blue font-semibold">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
            <div className="text-sm">Published July 21, 2022</div>
            {/* <div className="text-md font-semibold underline">
              International, USA Camping
            </div> */}
            <div>
              <p className="text-textgrey my-6"></p>
              <p className="text-textgrey my-6">
                {data.data.attributes.description}
              </p>
            </div>
            {/* <div className="flex w-full justify-center my-6">
              <img src="./img/blog-banner.png" />
            </div>
            <div>
              <p className="text-textgrey my-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                nec lorem vel nunc semper ultricies. Quisque porta efficitur
                imperdiet. In in augue bibendum, ullamcorper ligula sit amet,
                vestibulum lorem. Phasellus ac enim non metus porttitor
                convallis. Sed feugiat, ante in sollicitudin interdum, magna
                turpis congue ante, id venenatis ligula erat sit amet diam. Sed
                quis tempor urna, vitae pretium ligula. Sed tincidunt, neque non
                feugiat lacinia, magna mi lobortis enim, in feugiat tellus
                mauris vel est.
              </p>
            </div> */}

            <ReactMarkdown>{data.data.attributes.subparagraph}</ReactMarkdown>
          </div>
        </div>
      </Section>
    </Page>
  );
}
