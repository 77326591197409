import React, { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(1);
  const { auth } = useContext(AuthContext);
  return (
    <nav className=" max-w-[1600px] mx-auto p-6 ">
      <div className="flex justify-between">
        <Link to="/">
          <img
            src="./img/svg-logo.svg"
            className="w-[270px] h-[58px]"
            alt="Site Logo"
          />
        </Link>
        <button
          className="md:hidden text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="hidden md:block">
          <Link to="/how-it-works" className="text-lg font-semibold">
            Learn More
          </Link>
          <div className="ml-[30px] inline-block">
            <button className="text-lg font-semibold border-[1px] border-blue px-4 py-1 ">
              {auth.jwt ? (
                <Link to="/accounts">
                  <i className="fa fa-user"></i> MY ACCOUNT
                </Link>
              ) : (
                <Link to="/sign-in">
                  <i className="fa fa-user"></i> Sign In
                </Link>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="md:hidden bg-blue text-white">
        <div
          //class="transition duration-150 ease-in-out"
          className={navbarOpen ? "hidden " : "flex flex-col m-2"}
        >
          <Link to="/how-it-works" className="text-lg font-semibold m-2">
            Learn More
          </Link>
          <div className="m-2">
            <button className="text-lg font-semibold border-[1px] border-blue px-4 py-1 ">
              <Link to="/accounts">
                <i className="fa fa-user"></i> MY ACCOUNT
              </Link>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
