import React from "react";
import Page from "../layout/page";
import Section from "../layout/section";
import { Helmet } from "react-helmet";

export default function PaymentSuccess() {
  return (
    <Page>
      <Helmet>
        <title>Payment Success-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex min-h-[60vh] justify-center items-center">
          Your payment was succesful
        </div>
      </Section>
    </Page>
  );
}
