import React, { useContext, useEffect, useState } from "react";
import FilterButton from "../../components/filter/filterButton";
// import BlogCard from "../../components/blog/blogCard";
// import Button from "../../components/form-kit/button";
import Card from "../../components/card";
import ReactPaginate from "react-paginate";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import qs from "qs";
export default function Purchased() {
  const { auth } = useContext(AuthContext);
  const [query, setQuery] = useState({});
  const [tags, setTags] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const isActive = (n) => {
    if (active === n) return true;
    return false;
  };

  const submit = (id, pages) => {
    // setQuery;

    setQuery((prev) => ({
      filters: {
        tags: {
          id: id
            ? {
                $eq: id,
              }
            : {},
        },
        location: {
          $containsi: search,
        },
      },
    }));
    // setData(null);
  };
  const getData = async () => {
    try {
      const res = await axios.get(
        `/itinerary-details/list?${qs.stringify(
          {
            ...query,
            pagination: {
              page: page,
              pageSize: 6,
            },
          },
          { encodeValuesOnly: true }
        )}`,
        {
          headers: {
            Authorization: `Bearer ${auth.jwt}`,
          },
        }
      );
      //console.log(res);
      setData(res.data);
    } catch (errors) {
      console.log(errors);
    }
  };
  const getTags = async () => {
    if (tags) return;
    try {
      let res = await axios.get("/tags");
      setTags(res.data.data);
      // console.log(res);
    } catch (errors) {}
  };

  useEffect(() => {
    // console.log("change");

    getData();
    if (!tags) getTags();
  }, [query, page]);
  if (!data || !tags) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <TailSpin
          height="80"
          width="80"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-[100%] md:w-[50%] my-2">
        <div className="text-center">
          <span className="text-h4 font-bold text-blue">
            Your Purchased Itineraries
          </span>
        </div>
      </div>
      <div className="w-[100%] md:w-[28%] mx-auto my-2 mb-10">
        <form>
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-grey sr-only dark:text-grey"
          >
            Search
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-blue"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              id="default-search"
              class="h-10 pl-10 w-full text-sm text-blue bg-white rounded-full border "
              placeholder="Search"
              required
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <button
              type="submit"
              class="text-white absolute right-0 bottom-0 bg-blue hover:bg-blue font-medium rounded-full text-sm w-10 h-10"
              onClick={(e) => {
                e.preventDefault();
                submit(active);
              }}
            >
              <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-wrap justify-center w-[100%] md:w-[80%] gap-4 mx-auto ">
        <FilterButton
          active={isActive(0)}
          onClick={() => {
            // setTags({});
            setActive(0);
            submit(0);
          }}
        >
          All
        </FilterButton>

        {tags.map((e, i) => (
          <FilterButton
            key={i}
            active={isActive(i + 1)}
            onClick={() => {
              setActive(i + 1);
              submit(i);
            }}
          >
            {e.attributes.Name}
          </FilterButton>
        ))}
      </div>
      <div className="flex flex-wrap justify-center my-8 gap-4">
        {data.data.map((e) => (
          <Link to={`/single-itinerary/${e.id}`} key={e.id}>
            <Card
              title={e.title}
              location={e.location}
              price={e.price}
              days={e.days}
              score={e.rating}
              img={e.images[0].url}
              author={e.author.username}
            />
          </Link>
        ))}
      </div>
      <div className="flex items-center justify-end w-[90%] my-10 mx-auto">
        <ReactPaginate
          breakLabel="...."
          previousLabel="<"
          nextLabel=">"
          onPageChange={(e) => {
            setPage(e.selected + 1);
            // getData(active, e.selected);
          }}
          pageRangeDisplayed={4}
          pageCount={data.pagination.pageCount}
          renderOnZeroPageCount={null}
          containerClassName="flex gap-2 text-sm"
          activeLinkClassName="bg-blue text-white"
          pageLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
          nextLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
          previousLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
        />
      </div>
    </div>
  );
}
