import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import axios from "../../api/axios";
import Button from "../../components/form-kit/button";
import AuthContext from "../../context/AuthProvider";

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [nPassword, setnPassword] = useState("");
  const [rPassword, setrPassword] = useState("");
  const { data, jwt } = useOutletContext();
  const { auth } = useContext(AuthContext);
  const getToken = async (token) => {
    const body = {
      currentPassword: password,
      password: nPassword,
      passwordConfirmation: rPassword,
    };
    try {
      const res = await axios({
        method: "POST",
        url: "/auth/change-password",
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      navigate("/accounts");
    } catch (error) {
      console.log(error.response.data.error);
    }
  };
  useEffect(() => {}, []);
  return (
    <form className="w-[100%] md:w-[60%] bg-white shadow-custom m-auto flex flex-col gap-6 p-8 relative">
      <Link to="/accounts/profile">
        <i className="fa fa-arrow-left cursor-pointer"></i>
      </Link>
      <div className="text-blue text-h4 font-semibold text-center">
        Change Password
      </div>
      <Input
        placeholder="Old Password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        type="password"
      />
      <Input
        placeholder="New Password"
        onChange={(e) => {
          setnPassword(e.target.value);
        }}
        type="password"
      />
      <Input
        placeholder="Retype New Password"
        onChange={(e) => {
          setrPassword(e.target.value);
        }}
        type="password"
      />

      <Button
        primary="true"
        onClick={(e) => {
          e.preventDefault();
          getToken(jwt);
        }}
      >
        Change Password
      </Button>
    </form>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-sm text-blue bg-white border-grey border-b-[1px]"
      {...props}
    />
  );
}
