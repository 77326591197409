import React, { useEffect, useState } from "react";
import Section from "../layout/section";
import Button from "../components/form-kit/button";
import ReactPaginate from "react-paginate";
import BlogCard from "../components/blog/blogCard";
import FilterButton from "../components/filter/filterButton";
import Page from "../layout/page";
import axios from "../api/axios";
import qs from "qs";
import { Helmet } from "react-helmet";
const query = qs.stringify(
  {
    pagination: {
      pageSize: 6,
    },
    populate: ["hero"],
  },
  { encodeValuesOnly: true }
);
export default function Blogs() {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const submit = async () => {
    setData(null);
    try {
      const res = await axios.get(
        `/blogs?${qs.stringify(
          {
            filters: {
              title: { $containsi: search },
            },
            pagination: {
              pageSize: 6,
              page: page,
            },
            populate: ["hero"],
          },
          { encodeValuesOnly: true }
        )}`
      );
      setData(res.data);
      //setPage(res.data.meta.pagination.page);
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getData = async () => {
    setData(null);
    try {
      const res = await axios.get(
        `/blogs?${qs.stringify(
          {
            filters: {
              title: { $containsi: search },
            },
            pagination: {
              pageSize: 6,
              page: page,
            },
            populate: ["hero"],
          },
          { encodeValuesOnly: true }
        )}`
      );
      setData(res.data);
      //setPage(res.data.data.meta.pagination.page);
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // console.log("refreshing");
    getData();
  }, [page]);

  return (
    <Page>
      <Helmet>
        <title>Blogs-Travel Swap Shop</title>
      </Helmet>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-2">
            <div className="text-center">
              <span className="text-h4 font-bold text-blue">OUR BLOGS</span>
            </div>
          </div>
          <div className="w-[28%] mx-auto my-2 mb-10">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-grey sr-only dark:text-grey"
            >
              Search
            </label>
            <div class="relative">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-blue"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                id="default-search"
                class="h-10 pl-10 w-full text-sm text-blue bg-white rounded-full border "
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <button
                class="text-white absolute right-0 bottom-0 bg-blue hover:bg-blue font-medium rounded-full text-sm w-10 h-10"
                onClick={(e) => {
                  e.preventDefault();
                  submit();
                }}
              >
                <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* <div className="flex flex-wrap justify-center w-[80%] gap-4 mx-auto ">
            <FilterButton active>All</FilterButton>
            <FilterButton>International</FilterButton>
            <FilterButton>USA Camping</FilterButton>
            <FilterButton>USA Road Trips</FilterButton>
            <FilterButton>Disney</FilterButton>
            <FilterButton>Weekend Trips</FilterButton>
            <FilterButton>Family Travel</FilterButton>
          </div> */}
          {data ? (
            <div className="flex flex-wrap justify-center my-8 gap-4">
              {data.data.map((e, i) => (
                <BlogCard
                  key={e.id}
                  title={e.attributes.title}
                  text={e.attributes.description}
                  img={e.attributes.hero.data.attributes.url}
                  date={e.attributes.publishedAt}
                  id={e.id}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
          {data ? (
            <div className="flex items-center justify-end w-[90%] my-10 mx-auto">
              {data.meta.pagination.page < data.meta.pagination.pageCount && (
                <ReactPaginate
                  breakLabel="...."
                  forcePage={page - 1}
                  previousLabel="<"
                  nextLabel=">"
                  onPageChange={(e) => {
                    let current = e.selected + 1;
                    setPage(current);
                  }}
                  pageRangeDisplayed={4}
                  // pageCount={data.meta.pagination.pageCount}
                  pageCount={4}
                  renderOnZeroPageCount={null}
                  containerClassName="flex gap-2 text-sm"
                  activeLinkClassName="bg-blue text-white"
                  pageLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
                  nextLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
                  previousLinkClassName="border-grey border-[1px] p-1 hover:bg-blue hover:text-white"
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Section>
    </Page>
  );
}
