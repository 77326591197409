import React, { useContext, useState, useRef } from "react";
import AuthContext from "../../context/AuthProvider";
import Button from "../../components/form-kit/button";
import axios from "../../api/axios";
// import axios from "axios";

import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import qs from "qs";

export default function Profile() {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const [err, setErr] = useState(false);
  const { data, jwt } = useOutletContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      // console.log(data);
      setName(data.username);
      setEmail(data.email);
    }
  }, [data]);
  const postData = async (id, token) => {
    var fdata = new FormData();
    fdata.append("files", img);
    fdata.append("refId", data.id);
    fdata.append("ref", "plugin::users-permissions.user");
    fdata.append("field", "avatar");
    fdata.append("source", "users-permissions");

    var fdataInfo = new FormData();
    fdataInfo.append("username", name);
    fdataInfo.append("email", email);

    try {
      setLoading(true);
      if (img) {
        const imgRes = await axios({
          method: "PUT",
          url: "users-permissions/users/profile-picture",
          data: fdata,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(imgRes);
      }

      const res = await axios({
        method: "put",
        url: "users-permissions/users/me",
        data: fdataInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setErr(false);
    } catch (error) {
      console.log(error);
      setErr(true);
    }
  };

  return (
    <div className="w-[100%] md:w-[90%] min-h-[60vh] relative">
      {err && (
        <div className="absolute w-full text-red text-center z-40">
          There was some error
        </div>
      )}

      {loading && (
        <div className="absolute bg-white opacity-[0.5] w-full h-full z-40"></div>
      )}

      <div className="relative flex flex-col md:flex-row justify-center gap-8  z-30 p-8">
        <div className="flex flex-col items-center gap-4">
          {!img ? (
            <div
              className="w-[100px] h-[100px] md:w-[200px] md:h-[200px] rounded-full"
              style={{
                
              
                background:data.avatar
                  ? `url(${data.avatar.url}) center / cover no-repeat `
                  : "var(--theme-blue) center / cover no-repeat " ,
               
              }}
            ></div>
          ) : (
            <div
              className="w-[100px] h-[100px] md:w-[200px] md:h-[200px] rounded-full"
              style={{
                background: `url(${URL.createObjectURL(img)}) center / cover no-repeat `,
              
              }}
            ></div>
          )}
          {/* <div className="flex text-lg text-blue underline gap-4">
            <a>Change Picture</a>
          </div> */}
          <Upload name="img" data={{ setImg, img }} />
        </div>
        <div className="flex w-[100%] md:w-[60%] flex-col">
          <div className="w-[100%] text-center text-h4 text-blue font-bold">
            Your Profile
          </div>
          <div className="text-h6">Update your photo and personal details</div>
          <form className="relative flex bg-white flex-col gap-8 z-30">
            <Input
              placeholder="Full Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Input
              placeholder="Email Id"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div>
              <Button
                primary
                onClick={(e) => {
                  e.preventDefault();
                  postData(data.id, jwt);
                }}
              >
                Save Changes
              </Button>
            </div>
            <div className="flex text-lg text-blue underline gap-4">
              <Link to="/accounts/change-password">Change Password</Link>
              <a
                className="cursor-pointer"
                onClick={() => {
                  setAuth((prev) => ({ ...prev, jwt: null }));

                  localStorage.removeItem("TSS__APP__JWT");

                  navigate("/");
                }}
              >
                Logout
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute bg-white w-full h-full shadow-custom  z-20 top-0"></div>
      <div className="absolute bg-yellow w-full h-full z-10 top-6 -right-[2rem] shadow-custom "></div>
    </div>
  );
}

function Input(props) {
  return (
    <input
      className="h-10 w-full text-sm text-blue bg-white border-grey border-b-[1px]"
      {...props}
    />
  );
}

function Upload({ name, data }) {
  const inputEl = useRef(null);
  const { img, setImg } = data;
  const changeHandler = (event) => {
    // console.log(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
  };

  return (
    <>
      <input
        ref={inputEl}
        type="file"
        name={name}
        onChange={changeHandler}
        style={{ display: "none" }}
        accept="image/*"
      />
      <div className="flex text-lg text-blue underline gap-4 cursor-pointer">
        <a onClick={() => inputEl.current.click()}>Change Picture</a>
      </div>
    </>
  );
}
