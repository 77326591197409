import Section from "../layout/section";
import Button from "../components/form-kit/button";
import { useEffect, useState } from "react";
import AccordionSection from "../components/faqAccordion";
import Page from "../layout/page";
import Whirligig from "react-whirligig";
import { Helmet } from "react-helmet";
const items = [
  { id: 1, title: "item #1" },
  { id: 2, title: "item #2" },
  { id: 3, title: "item #3" },
  { id: 4, title: "item #4" },
  { id: 5, title: "item #5" },
  { id: 6, title: "item #5" },
  { id: 7, title: "item #5" },
  { id: 8, title: "item #4" },
  { id: 9, title: "item #5" },
  { id: 10, title: "item #5" },
  { id: 11, title: "item #5" },
];
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return innerWidth;
}
export default function AboutUs() {
  let whirligig;
  const next = () => whirligig.next();
  const prev = () => whirligig.prev();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Page>
      <Helmet>
        <title>Home-Travel Swap Shop</title>
        <link rel="canonical" href="https://travelswapshop.com/about-us" />
      </Helmet>
      <Section>
        <div className="flex flex-col md:flex-row justify-center gap-8 items-center py-8">
          <div className="w-[36vw] md:w-[300px]">
            <img src="gwen.png" alt="Founder of Website Gwen Romer" />
          </div>
          <div className="w-[80%] md:w-[50%]">
            <div className="text-blue text-h4 font-bold">Background</div>
            <div className="">
              I am someone who takes endless time to plan itineraries for
              vacations--searching the perfect places to visit, stay and eat! I
              have over 15 years of professional experience planning travel
              itineraries for individuals and university students and want to
              help everyone take amazing, thoughtful trips.
              <br />
              <br /> After my last trip to Italy with my family I thought about
              sharing my itinerary with people online. But before I did, I
              realized I spent 60+ hours creating this itinerary and instead of
              sharing it, maybe I could sell it for a reasonable cost. I could
              make some money for the work I did, and others could save time
              planning. It’s a win-win! <br />
              <br />
              Travel Swap Shop was created to save us all time and energy by
              providing a marketplace where we can search and sell pre-made
              itineraries! Travel, swap and shop!
            </div>
          </div>
        </div>
      </Section>
      {/* <Section className="bg-bgblue">
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                Why sell your itineraries?{" "}
              </span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                Viverra vitae congue eu consequat. Mauris rhoncus aenean vel
                elit scelerisque mauris
              </span>
            </div>
          </div>
          <div className="flex flex-wrap justify-center w-[85%] gap-8 my-8">
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/upload.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/price.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/create_account.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
            <SellLayout
              title={"Lorem ipsum dolor sit"}
              img="./img/upload.png"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing "
            />
          </div>
          <div className="my-6">
            <Button
              // onClick={() => {
              //   navigate("how-it-works");
              // }}
              primary="true"
            >
              Become a Seller
            </Button>
          </div>
        </div>
      </Section>
      <Section containerStyle="relative carousel-wrapper">
        <div className="text-h4 font-bold text-center text-blue mt-8">
          Testimonials
        </div>
        <div className="flex w-full justify-center my-2">
          <div className="text-center w-[40%]">
            Viverra vitae congue eu consequat. Mauris rhoncus aenean vel elit
            scelerisque mauris
          </div>
        </div>
        <button
          className="w-[50px] h-[50px] rounded-[50%] bg-grey 
          text-h4 absolute top-0 bottom-0 my-auto left-4
          z-20"
          onClick={prev}
        >
          {"<"}
        </button>
        <Whirligig
          visibleSlides={
            Math.floor(windowSize / 350) > 3 ? 3 : Math.floor(windowSize / 350)
          }
          gutter="1em"
          slideTo={0}
          ref={(_whirligigInstance) => {
            whirligig = _whirligigInstance;
          }}
          className="overflow-hidden relative"
        >
          {items.map((item, i) => (
            <div
              key={i}
              className="my-8 my-12 m-auto flex flex-col gap-4 justify-center 
            items-center 
            z-10"
            >
              <div className="bg-grey h-[190px] w-[190px] rounded-full"></div>
              <div className="text-blue text-lg">Niles Peppertrout</div>
              <p className="text-center w-[80%]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum porta nulla eu ipsum pretium feugiat. Mauris non
                augue mattis, semper augue facilisis, vestibulum mauris.
              </p>
            </div>
          ))}
        </Whirligig>
        <button
          className="w-[50px] h-[50px] rounded-[50%] bg-grey text-h4 absolute top-0 bottom-0 my-auto right-4"
          onClick={next}
        >
          {">"}
        </button>
      </Section>
      <Section>
        <div className="flex flex-col items-center">
          <div className="w-[50%] my-8">
            <div className="text-center my-2">
              <span className="text-h4 font-bold text-blue">
                Frequently asked questions
              </span>
            </div>
            <div className="text-center">
              <span className="text-lg text-black w-[40%]">
                How can we help you?
              </span>
            </div>
          </div>
          <div className="my-8 w-[80%]">
            <AccordionSection />
          </div>
        </div>
      </Section> */}
    </Page>
  );
}

function HeroElement({ title, text, img }) {
  return (
    <div className="w-1/5">
      <img src={img} className="mx-auto" alt={"Hero section"} />
      <p className="text-center text-lg text-blue font-bold">{title}</p>
      <p className="text-center">{text}</p>
    </div>
  );
}

function SellLayout({ title, text, img }) {
  return (
    <div className="flex gap-2 max-w-[450px] items-center">
      <img src={img} width={"20%"} alt={`${title}`} />
      <div>
        <div className="text-blue text-md font-bold">{title}</div>
        <span className="text-grey text-sm font-semibold w-[80%]">{text}</span>
      </div>
    </div>
  );
}
