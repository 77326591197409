import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from "../../components/form-kit/button";
// import Card from "../../components/card";
// import {
//   Link,
//   NavLink,
//   // Outlet,
//   // useNavigate,
//   useOutlet,
//   useOutletContext,
// } from "react-router-dom";
import Select from "react-select";
import Checkbox from "../../components/filter/checkbox";
import { useDropzone } from "react-dropzone";
import data from "../../utils/new_file.json";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { TailSpin } from "react-loader-spinner";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

export default function Edit() {
  const [data, setData] = useState();
  const [img, setImg] = useState();
  const { auth } = useContext(AuthContext);
  const { id } = useParams();
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    const getData = async (token) => {
      try {
        const res = await axios.get(`/itineraries/${id}`, {
          headers: {
            Authorization: auth.jwt ? `Bearer ${auth.jwt}` : "",
          },
        });

        // console.log(res.data);
        // console.log(rev.data);
        setData(res.data.data);
        // console.log(rev.data.data.map((e) => "a"));
        setImg(res.data.data.images);
      } catch (errors) {
        console.log(errors);
      }
      try {
        const details = await axios.get(`/itinerary-details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPdf(details.data);
      } catch (error) {
        setPdf(error.response.data);
      }
    };

    if (!data) getData(auth.jwt);
  }, [auth.jwt]);
  if (!data) {
    return <></>;
  }
  if (!pdf) {
    return <></>;
  }
  return <ItineraryForm response={data} pdfFile={pdf} />;
}

function ItineraryForm({ response, pdfFile }) {
  // const { navigate } = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    setPdf(acceptedFiles[0]);
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  const { auth } = useContext(AuthContext);
  const [error, setError] = useState("");

  const [imgError, setImgError] = useState("");
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(() => (response ? response.title : ""));
  const [location, setLocation] = useState(() =>
    response ? response.location : ""
  );
  const [tags, setTags] = useState([]);
  const [economy, setEconomy] = useState(null);
  const [edited, setEdited] = useState([]);
  const [pdf, setPdf] = useState(() => {
    return null;
  });
  const [img, setImg] = useState(() => {
    return [];
  });
  useEffect(() => {
    setEconomy(response.cost);
    setTags(() => {
      let t = [];
      response.tags.forEach((e) => {
        t.push(e.id);
      });

      return t;
    });
    // console.log(pdfFile);
    setImg(() => {
      let temp = [];
      if (!response) {
        return [];
      }
      response.images.forEach(async (e) => {
        let blob = await fetch(e.url).then((r) => r.blob());
        const file = new File([blob], e.name, { type: blob.type });

        temp.push(file);
      });
      return temp;
    });
  }, [response]);
  const validationErrors = (value, n) => {
    if (!value) {
      setError((prev) => {
        // prev[n] = `Please fill ${n}`;

        return "Please fill all the form fields";
      });

      return false;
    }
    setError("");
    // setError((prev) => ({ ...prev, n: "Filled" }));
    return true;
  };

  const [days, setDays] = useState(() => (response ? response.days : null));
  const [price, setPrice] = useState(() => (response ? response.price : null));

  const [description, setDescription] = useState(() =>
    response ? response.description : ""
  );
  const imgValidation = () => {
    if (img.length === 0) {
      setImgError("Please add atleast one image");
      return false;
    }
    setImgError("");
    return true;
  };
  const validation = () => {
    let errs = 0;
    if (!validationErrors(title, "title")) errs += 1;
    // if (!validationErrors(pdf, "pdf")) errs += 1;
    if (!validationErrors(location, "location")) errs += 1;
    if (!validationErrors(days, "days")) errs += 1;
    if (!validationErrors(price, "price")) errs += 1;
    if (!validationErrors(description, "description")) errs += 1;
    if (!validationErrors(economy, "economy")) errs += 1;
    return errs;
  };
  const submitRequest = async () => {
    // console.log(edited);
    setSubmitting(true);
    var fdata = new FormData();
    if (validation() || !imgValidation()) {
      imgValidation();
      if (validation()) setError("Please fill all the fields");
      setSubmitting(false);
      window.scrollTo(0, 0);

      return 0;
    }
    fdata.append("id", response.id);
    fdata.append("title", title);
    fdata.append("location", location);
    fdata.append("days", days);
    fdata.append("price", price);
    fdata.append("description", description);
    fdata.append("tags", JSON.stringify(tags));
    fdata.append("edited", JSON.stringify(edited));
    fdata.append("cost", economy);
    // fdata.append("files", img);
    //  fdata.append("files")
    img.forEach((im, i) => {
      fdata.append("files", im);
      // if (edited.includes(i)) {
      //   fdata.append("files", im);
      //   // console.log(i);
      // }
      // console.log(i);
    });
    if (pdf) {
      fdata.append("pdf", pdf);
    }

    // console.log(fdata);
    try {
      const res = await axios({
        method: "POST",
        url: "itinerary/update/",
        data: fdata,

        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.jwt}`,
        },
      });

      if ((res.status = 200)) {
        setSuccess(true);
      }
    } catch (errors) {
      setSubmitting(false);
      setError("There was some error while submiting");
    }
  };

  if (success) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        Thank you! Your submission will be reviewed.
      </div>
    );
  }
  if (response && !pdfFile) {
    return <></>;
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="text-h4 text-blue font-bold self-center">
        Manage your itineraries
      </div>

      <div className="self-center">
        Add and manage the itineraries you have listed
      </div>
      {submitting ? (
        <div className="flex w-full justify-center items-center">
          <TailSpin
            height="80"
            width="80"
            color="blue"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="bg-white shadow-custom p-20">
            <form>
              <div className="flex flex-col gap-4">
                <div className="text-blue text-h6 font-bold">
                  1. Itineraries Details
                </div>
                <div className="text-red text-lg">{error || imgError}</div>
                <Input placeholder="Title" state={title} setState={setTitle} />
                <Location state={location} setState={setLocation} />
                <Input
                  placeholder="Days"
                  type="number"
                  state={days}
                  setState={setDays}
                />
                <div className="py-[2px] px-[8px] my-4">
                  <div className="text-lg text-grey my-4">Tags</div>

                  {[
                    "International",
                    "USA",
                    "USA Camping",
                    "USA Road Trips",
                    "Disney",
                    "Family Travel",
                    "Weekend Trips",
                  ].map((tagCheck, i) => (
                    <Checkbox
                      label={tagCheck}
                      key={i}
                      checked={tags.includes(i + 1)}
                      onChange={() => {
                        // console.log(tags)
                        if (tags.includes(i + 1)) {
                          setTags((prevTags) =>
                            prevTags.filter((t) => t !== i + 1)
                          );
                        } else {
                          setTags((prevTags) => [...prevTags, i + 1]);
                        }
                      }}
                      // checked={}
                    />
                  ))}
                </div>

                <div className="py-[2px] px-[8px] my-4">
                  <div className="text-lg text-grey my-4">Cost of Trip</div>
                  <div className="flex gap-6">
                    {["Economy", "Moderate", "Luxury"].map((econ, i) => (
                      <Checkbox
                        label={econ}
                        key={i}
                        checked={economy === econ}
                        onChange={() => {
                          setEconomy(econ);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <Pricing
                  placeholder="Price"
                  type="number"
                  state={price}
                  setState={setPrice}
                />
                <textarea
                  placeholder="Description"
                  className="border-grey text-lg text-blue teborder-b-[1px] w-full py-[2px] px-[8px] "
                  rows="4"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex">
                  <div className="text-blue text-h6 font-bold">2.&nbsp;</div>
                  <div>
                    <div className="text-blue text-h6 font-bold">Photos</div>
                    <div className="text-blue text-lg font-bold">
                      Upload atleast one high quality photo of your itinerary.
                    </div>
                    <div className="text-red text-lg">{imgError}</div>
                    <div className="w-[80%] my-8 flex flex-wrap gap-14 justify-center">
                      <Upload
                        featured="true"
                        name="1"
                        setState={setImg}
                        index={0}
                        err={setImgError}
                        edit={setEdited}
                        initial={response ? response.images[0] : null}
                      />
                      <Upload
                        name="2"
                        setState={setImg}
                        index={1}
                        err={setImgError}
                        edit={setEdited}
                        initial={response ? response.images[1] : null}
                      />
                      <Upload
                        name="3"
                        setState={setImg}
                        index={2}
                        err={setImgError}
                        edit={setEdited}
                        initial={response ? response.images[2] : null}
                      />
                      <Upload
                        name="4"
                        setState={setImg}
                        index={3}
                        err={setImgError}
                        edit={setEdited}
                        initial={response ? response.images[3] : null}
                      />
                      <Upload
                        name="5"
                        setState={setImg}
                        index={4}
                        err={setImgError}
                        edit={setEdited}
                        initial={response ? response.images[4] : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex">
                  <div className="text-blue text-h6 font-bold">3.&nbsp;</div>
                  <div>
                    <div className="text-blue text-h6 font-bold">
                      Upload Itinerary
                    </div>
                    <div className="text-blue text-lg font-bold">
                      Upload the Itinerary in .pdf or .doc file format which
                      will be only accessible after purchasing it.
                    </div>
                  </div>
                </div>
                <div
                  {...getRootProps({
                    className:
                      "dropzone bg-bggrey border w-full h-[180px] border-dashed flex flex-col justify-center items-center",
                  })}
                >
                  <input {...getInputProps()} />
                  <img src="./img/dropzone-icon.svg" alt="" />
                  <p className="text-lg font-bold">
                    Drag and drop or browse your files
                  </p>
                  <p>{pdf ? pdf.name : JSON.stringify(pdfFile.data.name)}</p>
                </div>
              </div>
              <div className="flex w-full flex-col my-6">
                <Button
                  primary="true"
                  onClick={(e) => {
                    e.preventDefault();
                    submitRequest();
                  }}
                >
                  Submit Request
                </Button>
              </div>
            </form>
            <p className="mt-16 mb-10 text-center">
              By continuning, you agree to Privacy policy.
            </p>
          </div>
        </>
      )}
    </div>
  );
}

function DropZone() {
  return <></>;
}

function Upload({ name, setState, index, featured, err, edit, initial }) {
  const inputEl = useRef(null);
  const [heif, setHeif] = useState(0);
  const [img, setImg] = useState(null);
  const changed = function () {
    edit((prev) => [...prev, index]);
  };

  const removeHandler = () => {
    inputEl.current.value = "";
    setState((prev) => {
      const newArray = prev.filter((_, i) => i !== index);
      return newArray;
    });
    setImg(null);
  };
  useEffect(() => {
    const getData = async () => {
      if (initial) {
        let blob = await fetch(initial.url).then((r) => r.blob());
        const file = new File([blob], initial.name, { type: blob.type });
        changeHandler({
          target: {
            files: [file],
          },
        });
      }
    };
    getData();
  }, [initial]);
  const changeHandler = async (event) => {
    const imageFile = event.target.files[0];
    if (event.target.files[0].size > 3500000) {
      err(
        "File size is too large. Please make sure the image size is less than 3MB."
      );
      return;
    }
    var newFile;

    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      setHeif(1);
      newFile = await heic2any({
        blob: imageFile,
        toType: "image/jpeg",
        quality: 1,
      });
      setHeif(0);
    } catch (error) {
      // console.log(error);
      newFile = imageFile;
      setHeif(0);
    }
    try {
      const compressedFile = await imageCompression(newFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      err("");

      setState((prev) => [
        ...prev.slice(0, index),
        compressedFile,
        ...prev.slice(index + 1),
      ]);

      setImg(URL.createObjectURL(compressedFile));
    } catch (error) {
      err(
        "The file type is unsuported or corrupted. Please make sure the image is less than 3MB"
      );
    }
  };

  return (
    <div
      className={`h-[200px] w-[200px] shadow-custom relative ${
        featured ? "border-[6px] border-blue border-opacity-0 " : ""
      } custom-background-style`}
      style={{
        background: `url(${img})`,
      }}
    >
      {heif === 1 ? (
        <div className="w-full h-full bg-white position:absolute opacity-[0.6]"></div>
      ) : (
        <></>
      )}
      <input
        ref={inputEl}
        type="file"
        name={name}
        onChange={(e) => {
          changeHandler(e);
          changed();
        }}
        style={{ display: "none" }}
        accept="image/*"
      />
      <a onClick={removeHandler}>
        <div className="cursor-pointer absolute right-0 top-0 h-[30px] w-[30px] bg-red opacity-[0.6] rounded-full flex justify-center items-center">
          <i className=" fa fa-x text-white"></i>
        </div>
      </a>
      <a onClick={() => inputEl.current.click()}>
        <div className="cursor-pointer absolute right-0 bottom-0 m-2 h-[40px] w-[40px] bg-blue rounded-full flex justify-center items-center">
          <i className=" fa fa-plus text-white"></i>
        </div>
      </a>
    </div>
  );
}
function Input(props) {
  const { state, setState } = props;
  return (
    <input
      className="outline-0 min-h-[38px] py-[2px] px-[8px] w-full text-lg text-blue bg-white border-grey border-b-[1px] "
      {...props}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
    />
  );
}
function Pricing(props) {
  const { state, setState } = props;
  return (
    <div className="relative">
      <input
        className="outline-0 min-h-[38px] py-[2px] px-[8px] w-full text-lg text-blue bg-white border-grey border-b-[1px] "
        {...props}
        value={state}
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <i className="fa fa-dollar absolute right-0 bottom-0 text-grey p-2"></i>
    </div>
  );
}

function Location(props) {
  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState(null);
  const [city, setCity] = useState(null);

  const { state, setState } = props;
  useEffect(() => {
    const getCurrent = function () {
      // console.log(props.state)
      let curr = props.state.split(",");
      if (curr.length === 3) {
        setCountry({ value: 1, label: curr[2] });
        setStates({ value: 1, label: curr[1] });
        setCity({ value: 1, label: curr[0] });
      } else if (curr.length === 2) {
        setCountry({ value: 1, label: curr[1] });
        setStates({ value: 1, label: curr[0] });
      } else if (curr.length === 1) {
        setCountry({ value: 1, label: curr[0] });
      }
    };
    getCurrent();
  }, [state]);

  const search = function (t) {
    let res = [];
    data.forEach((e) => {
      if (res.length > 3) {
        return;
      }
      let curren = res.length;
      e.states.forEach((v) => {
        if (res.length > 3) {
          return;
        }
        let cur = res.length;
        v.cities.forEach((c) => {
          if (res.length > 3) {
            return;
          }
          if (c.toLowerCase().includes(t.toLowerCase()))
            res.push({
              value: `${c},${v.name},${e.name}`,
              label: `${c}, ${v.name}, ${e.name}`,
            });
        });
        if (
          res.length == cur &&
          v.name.toLowerCase().includes(t.toLowerCase())
        ) {
          res.push({
            value: `${v.cities[0] ? v.cities[0] + "," : ""}${v.name},${e.name}`,
            label: `${v.cities[0] ? v.cities[0] + "," : ""} ${v.name}, ${
              e.name
            }`,
          });
        }
      });
      if (
        res.length == curren &&
        e.name.toLowerCase().includes(t.toLowerCase())
      )
        res.push({
          value: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
          label: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
        });
    });
    setOptions(res);
  };
  const searchCountry = function (t) {
    let res = [];
    data.forEach((e, i) => {
      if (e.name.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: i, label: `${e.name}` });
      }
    });
    setOptions(res);
  };
  const searchState = function (t) {
    let res = [];

    data[country.value].states.forEach((e, i) => {
      if (e.name.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: i, label: `${e.name}` });
      }
    });
    setOptions(res);
  };
  const searchCities = function (t) {
    let res = [];

    data[country.value].states[states.value].cities.forEach((e) => {
      if (e.toLowerCase().includes(t.toLowerCase())) {
        res.push({ value: e, label: e });
      }
    });
    setOptions(res);
  };
  const colourStyles = {
    container: (styles) => ({ ...styles }),
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      borderWidth: isFocused ? 0 : "0px 0px 1px",
      borderColor: "var(--theme-grey)",
      boxShadow: "none",

      minWidth: "12rem",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "var(--theme-blue)";
      return {
        ...styles,
        fontSize: "1.125em",
        backgroundColor: "fff",
        color: color,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: "var(--theme-blue)",
        fontSize: "1.125em",
      };
    },
    placeholder: (styles, { data }) => {
      return {
        ...styles,
        color: "var(--theme-grey)",
        fontSize: "1.125em",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: "var(--theme-grey)",
      };
    },
  };

  const handleChangeCountry = (v) => {
    setCountry(v);
    setStates(null);
    setCity(null);
    setState(`${v.label}`);
    // console.log(state);
  };
  const handleChangeStates = (v) => {
    setStates(v);
    setCity(null);
    setState(`${v.label},${country.label}`);
    // console.log(state);
  };
  const handleChangeCity = (v) => {
    setCity(v);
    setState(`${v.label}, ${states.label}, ${country.label}`);
    // console.log(state);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <Select
        styles={colourStyles}
        options={options}
        placeholder={country ? country : "Country"}
        onChange={handleChangeCountry}
        onFocus={() => {
          searchCountry("");
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchCountry(e);
        }}
        value={country}
      />
      <Select
        styles={colourStyles}
        options={options}
        placeholder={states ? states : "State"}
        onChange={handleChangeStates}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchState(e);
        }}
        onFocus={() => {
          setOptions([]);
        }}
        value={states}
        isDisabled={country ? false : true}
      />
      <Select
        styles={colourStyles}
        options={options}
        placeholder={city ? city : "City"}
        onChange={handleChangeCity}
        onFocus={() => {
          setOptions([]);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          searchCities(e);
        }}
        value={city}
        isDisabled={states ? false : true}
      />
    </div>
  );
}
