import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { Helmet } from "react-helmet";
export default function Page({ children }) {
  return (
    <div className="w-full bg-bgcolor">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Travel Swap Shop</title>

        <meta
          name="description"
          content="Save time and energy by searching for a pre-made itinerary you can use or adapt and share itineraries you have already created!"
        />
        <meta
          name="keyword"
          content="cheap flights, best vacation spots, travel deals, family-friendly hotels, beach resorts, adventure travel, romantic getaways, backpacking, cruise vacations"
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <Navbar />
      <div className="min-h-[60vh]">{children}</div>

      <Footer />
    </div>
  );
}
