import React from "react";

export default function Section({ children, className, containerStyle }) {
  return (
    <section className={className}>
      <div className={"max-w-[1600px] mx-auto " + containerStyle}>
        {children}
      </div>
    </section>
  );
}
