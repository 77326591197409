export const questions = [
  {
    ques: "What makes a good itinerary?",
    ans:"Tried and true suggestions.  Things that you have tried and can recommend or not recommend.  Here are some ideas of how to structure your information so that it’s easy to read for other travelers.  See our suggested format <a href='./TSS What makes a great itinerary.docx' class='text-blue'>here</a>"
  },
  {
    ques: "What if I can’t get a reservation or booking at a suggested site?",
    ans:"If you can’t get a reservation at the suggested site on the itinerary, Google the general vicinity for other options.  Sometimes Sellers will offer several suggestions."
  },
  {
    ques: "What if I didn’t like a particular itinerary?",
    ans:"We have a review system so that people can comment and review itineraries.  Please offer friendly suggestions if possible.  Sellers can update and edit to make itineraries better."  
  },
  {
    ques: "When will I get a payment for itineraries I’ve sold on the website?",
    ans: "Travel Swap Shop will distribute payments to itinerary creators promptly after purchases are made."
  },
  {
    ques:"Can I get a refund if I don’t like the itinerary?",
    ans:"All sales are final and non-refundable.  Travel Swap Shop does not offer any. Money-back guarantees.  For concerns please contact, gwen@travelswapshop.com"
  },
  {
    ques:"What payment system do you use?",
    ans:"We use Stripe for customers to make purchase and distribute money to Sellers.  Buyers and Sellers will need to create a Stripe account."
  },
  {
    ques:"How quickly will my itinerary be published after I submit?",
    ans:"Our team will review itineraries as quickly as possible so that you can start sharing them with other travelers. Please allow at 24 hours for review."
  }
];
