import React from "react";
import Button from "../form-kit/button";
import Input from "./input";
import FilterSelect from "./select";
// import { useNavigate } from "react-router-dom";
export default function Search({
  value,
  days,
  onSearch,
  daysChange,
  onSubmit,
  disabled,
}) {
  // const navigate = useNavigate();
  return (
    <div className="w-full bg-blue py-10  flex flex-col md:flex-row items-center md:justify-center gap-4">
      <Input values={value} onChange={onSearch} />
      {/* <FilterSelect placeholder={"Trip Duration"} onChange={daysChange} /> */}
      <Button onClick={onSubmit} disabled={disabled}>
        Search
      </Button>
    </div>
  );
}
