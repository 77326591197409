import React from "react";
import { Blocks } from "react-loader-spinner";
export default function Loader() {
  return (
    <div className="w-full h-[100vh] flex flex-col gap-8 justify-center items-center bg-bgcolor">
      <img src="./img/logo_transparent.png" width={250} alt="Site logo" />

      <Blocks
        visible={true}
        height="20vh"
        width="20vh"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
      />
    </div>
  );
}
