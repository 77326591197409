import React from "react";
import { useState } from "react";

import Select from "react-select";
import data from "../../utils/new_file.json";
export default function Input({ values, onChange }) {
  return (
    <div className="flex flex-col w-[80%] md:w-[50%]">
      <span className="text-black text-xs absolute mx-4 mt-1">Search by</span>
      <input
        onChange={(e) => {
          onChange(e);
        }}
        value={values}
        placeholder="Destination"
        className="placeholder-style text-blue border-black border-[1px] py-4 px-4 "
      />
    </div>
  );
}

// const options = [
//   { value: "0-5", label: "0-5 Days" },
//   { value: "5-10", label: "5-10 Days" },
//   { value: "10+", label: "10+ Days" },
// ];
// const colourStyles = {
//   container: (styles) => ({ ...styles, width: "50%" }),
//   control: (styles) => ({
//     ...styles,
//     backgroundColor: "white",
//     padding: "0.6rem",
//     minWidth: "12rem",
//   }),
//   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//     const color = "var(--theme-blue)";
//     return {
//       ...styles,
//       backgroundColor: "fff",
//       color: color,
//       cursor: isDisabled ? "not-allowed" : "default",
//     };
//   },
//   dropdownIndicator: (styles) => {
//     return {
//       ...styles,
//       color: "var(--theme-blue)",
//     };
//   },
// };

// export default function FilterSelect({ placeholder }) {
//   const [options, setOptions] = useState([]);
//   const search = function (t) {
//     let res = [];
//     data.forEach((e) => {
//       if (res.length > 3) {
//         return;
//       }
//       let curren = res.length;
//       e.states.forEach((v) => {
//         if (res.length > 3) {
//           return;
//         }
//         let cur = res.length;
//         v.cities.forEach((c) => {
//           if (res.length > 3) {
//             return;
//           }
//           if (c.toLowerCase().includes(t.toLowerCase()))
//             res.push({
//               value: `${c},${v.name},${e.name}`,
//               label: `${c}, ${v.name}, ${e.name}`,
//             });
//         });
//         if (
//           res.length == cur &&
//           v.name.toLowerCase().includes(t.toLowerCase())
//         ) {
//           res.push({
//             value: `${v.cities[0] ? v.cities[0] + "," : ""}${v.name},${e.name}`,
//             label: `${v.cities[0] ? v.cities[0] + "," : ""} ${v.name}, ${
//               e.name
//             }`,
//           });
//         }
//       });
//       if (
//         res.length == curren &&
//         e.name.toLowerCase().includes(t.toLowerCase())
//       )
//         res.push({
//           value: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
//           label: `${e.states[0].cities[0] || ""},${e.states[0].name},${e.name}`,
//         });
//     });
//     setOptions(res);
//   };
//   return (
//     <Select
//       styles={colourStyles}
//       options={options}
//       placeholder={placeholder}
//       components={{
//         IndicatorSeparator: () => null,
//       }}
//       onInputChange={(e) => {
//         search(e);
//       }}
//     />
//   );
// }
