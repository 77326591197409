import React, { useState, useEffect } from "react";
import Page from "../layout/page";
import Section from "../layout/section";
import Button from "../components/form-kit/button";
import Whirligig from "react-whirligig";
import { Star } from "../components/card";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { useContext } from "react";
import Loader from "../layout/loader";
import AuthContext from "../context/AuthProvider";
// import { localeData } from "moment";
import { Helmet } from "react-helmet";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return innerWidth;
}
export default function SingleItinerary() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const [pdf, setPdf] = useState();
  const [img, setImg] = useState([]);
  const [data, setData] = useState(null);
  const [reviews, setReview] = useState(null);
  let whirligig;
  const [modal, setModal] = useState(0);
  const next = () => whirligig.next();
  const prev = () => whirligig.prev();
  const checkout = async () => {
    if (!auth.jwt) {
      navigate("/accounts", { replace: true });
      return;
    }
    try {
      const checkout = await axios({
        method: "post",
        url: "users-permissions/stripe/checkout",
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
        data: {
          id: id,
          author: data.author.id,
        },
      });
      window.location.href = checkout.data.session.url;
      // console.log(checkout.data.session.url);
      return;
    } catch (errors) {
      console.log(errors);
    }
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    const getData = async (token) => {
      try {
        const res = await axios.get(`/itineraries/${id}`, {
          headers: {
            Authorization: auth.jwt ? `Bearer ${auth.jwt}` : "",
          },
        });
        const rev = await axios.get(
          `reviews?populate=%2A&filters[itinerary]=${id}&pagination[pageSize]=3`
        );

        // console.log(res.data);
        // console.log(rev.data);
        setData(res.data.data);
        setReview(rev.data);
        // console.log(rev.data.data.map((e) => "a"));
        setImg(res.data.data.images);
      } catch (errors) {
        console.log(errors);
      }
      try {
        const details = await axios.get(`/itinerary-details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPdf(details.data);
      } catch (error) {
        setPdf(error.response.data);
      }
    };
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    if (!data) getData(auth.jwt);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [auth.jwt]);
  if (!data || !img || !reviews || !pdf) {
    return <Loader />;
  }
  return (
    <Page>
      <Helmet>
        <title>{data.title}-Travel Swap Shop</title>
        <link
          rel="canonical"
          href={`https://travelswapshop.com/single-itinerary/${id}`}
        />
      </Helmet>
      {modal ? (
        <div className="fixed top-0 w-full h-full flex justify-center items-center z-30 ">
          <div className="h-full w-full absolute top-0 z-10 bg-black opacity-[0.2]"></div>

          <Modal
            toggle={() => {
              setModal((prev) => !prev);
            }}
            id={id}
            data={data}
            auth={auth}
          />
        </div>
      ) : (
        <></>
      )}

      {/* <Section>
        <div className="">
          <Carousel itemsToShow={4} itemPadding={[0, 0]}>
            {items.map((item) => (
              <div className="w-[350px] h-[350px] my-8 flex justify-center items-center bg-white shadow-custom">
                <div
                  className="w-[320px] h-[320px] margin-auto"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(./img/demo-single.png)`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            ))}
          </Carousel>
        </div>
      </Section> */}
      <Section containerStyle="relative carousel-wrapper">
        <button
          className="w-[50px] h-[50px] rounded-[50%] bg-grey 
          text-h4 absolute top-0 bottom-0 my-auto left-4
          z-20"
          onClick={prev}
        >
          {"<"}
        </button>
        <Whirligig
          visibleSlides={
            Math.floor(windowSize / 350) > img.length
              ? img.length
              : Math.floor(windowSize / 350) > 4
              ? 4
              : Math.floor(windowSize / 350)
          }
          gutter="1em"
          slideTo={4}
          ref={(_whirligigInstance) => {
            whirligig = _whirligigInstance;
          }}
          className="overflow-hidden relative"
          style={{ justifyContent: "center" }}
        >
          {img.map((item, i) => (
            <div
              key={i}
              className="w-[350px] h-[350px] my-8 m-auto flex justify-center 
            items-center bg-white shadow-custom
            z-10"
            >
              <div
                className="w-[320px] h-[320px] margin-auto"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${item.url})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          ))}
        </Whirligig>
        <button
          className="w-[50px] h-[50px] rounded-[50%] bg-grey text-h4 absolute top-0 bottom-0 my-auto right-4"
          onClick={next}
        >
          {">"}
        </button>
      </Section>
      {data && (
        <Section>
          <div className="flex flex-col gap-4 md:gap-0 md:flex-row p-4">
            <div className="w-[100%] md:w-[75%]">
              <div className="text-h4 text-blue font-bold">{data.title}</div>
              <div className="my-4">
                <div className="text-md text-blue font-bold flex items-center">
                  {data.days} days&nbsp;&nbsp;&nbsp;{data.location}&nbsp; &nbsp;
                  <Star rating={data.rating} />
                </div>
                <div className="text-sm text-grey flex gap">
                  {data.tags.map((e, i) => (
                    <span key={i} className="mr-4">
                      {e.Name}
                    </span>
                  ))}
                </div>
              </div>
              <p className="text-sm text-grey">{data.description}</p>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-h4 font-semibold">US$ {data.price}</div>
              {pdf.data ? (
                <button
                  className="text-lg font-semibold border-[1px] border-blue px-4 py-3 hover:bg-blue hover:text-white "
                  onClick={() => {
                    window.open(pdf.data.url, "_blank");
                  }}
                >
                  Download <i className="fa fa-download"></i>
                </button>
              ) : (
                <button
                  className="text-lg font-semibold border-[1px] border-blue px-4 py-3 hover:bg-blue hover:text-white "
                  onClick={checkout}
                >
                  Buy <i className="fa fa-dollar"></i>
                </button>
              )}
            </div>
          </div>
          <div className="flex my-4 items-center p-4">
            <div className="w-[80px] h-[80px]">
              <ProfilePic pic={data.author} />
            </div>

            <div className="mx-4">
              <div className="text-lg text-blue font-bold">Curated by</div>

              <div className="text-sm text-blue font-bold mt-2">
                {data.author.username}
              </div>
              {/* <div className="text-xs text-grey">California</div> */}
            </div>
          </div>
        </Section>
      )}
      <Section containerStyle="p-4">
        {pdf.data && (
          <div className="w-full flex flex-col items-center">
            <span className="text-lg text-blue font-bold">
              Review this itineary
            </span>
            <span className="text-md text-grey">
              Share your thoughts with other customers
            </span>
            <button
              className="my-4 text-lg font-semibold border-[1px] border-blue rounded-full px-4 py-2 hover:bg-blue hover:text-white "
              onClick={() => {
                setModal((prev) => !prev);
              }}
            >
              Write a customer review
            </button>
          </div>
        )}

        <div className="my-2 text-lg text-blue font-bold">{`Reviews(${reviews.data.length})`}</div>
        <div className="flex flex-col mb-6 gap-6">
          {reviews.data.map((e, i) => (
            <Reviews
              key={i}
              rating={e.rating}
              review={e.comment}
              user={e.user}
            />
          ))}
        </div>
        <div className="text-center mb-4">
          {reviews.page < reviews.pageCount && (
            <Button primary>Load More</Button>
          )}
        </div>
      </Section>
    </Page>
  );
}

function ProfilePic({ pic }) {
  if (pic) {
    if (pic.avatar)
      return (
        <div
          className="w-[100%] h-[100%] rounded-[100%] bg-blue flex justify-center items-center"
          style={{
            background: `url(${pic.avatar.url})`,
            backgroundSize: "100%",
          }}
        ></div>
      );
  }
  return (
    <div className="w-[100%] h-[100%] rounded-[100%] bg-blue flex justify-center items-center">
      {pic && <div className="text-white text-[170%]">{pic.username[0]}</div>}
    </div>
  );
}

function Reviews({ rating, review, user }) {
  return (
    <div className="flex p-8 gap-2 bg-reviewsblue">
      <div className="w-[20%] md:w-[10%]">
        <div className="w-[50px] h-[50px]">
          <ProfilePic
            pic={{ ...user, avatar: user.img ? { url: user.img } : null }}
          />
        </div>
      </div>

      <div className="flex flex-col items-start gap-2">
        <div className="text-sm text-grey font-semibold">{user.username}</div>
        <Star rating={rating} />
        <div className="text-sm text-grey ">{review}</div>
        {/* <div className="text-sm text-grey font-bold">Read More</div> */}
      </div>
    </div>
  );
}

function Modal({ toggle, id, data, auth }) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [me, setMe] = useState(null);
  useEffect(() => {
    const getProfile = async () => {
      let res = await axios.get("users/me?populate=avatar", {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      });
      setMe(res.data);
    };
    getProfile();
  });
  const submit = async () => {
    let formData = new FormData();
    formData.append("comment", review);
    formData.append("rating", rating);
    formData.append("itinerary", id);

    try {
      let res = await axios({
        method: "post",
        url: "/reviews",
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
        data: formData,
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="relative w-[60%] h-[40%] bg-white z-20 flex flex-col items-start justify-center px-[4%]">
      <button className="text-h5 absolute top-0 right-0 p-8" onClick={toggle}>
        X
      </button>
      <div className="text-blue text-h6 font-bold">Rating</div>
      <Star rating={rating} clickable change={setRating} />
      <div className="text-blue text-h6 font-bold mt-4">Review</div>
      <div className="flex w-full items-center justify-between">
        <div className="w-[10%]">
          <div className="w-[50px] h-[50px]">
            <ProfilePic pic={me} />
          </div>
        </div>
        <div className="w-[95%]">
          <form className="w-full">
            <div class="relative w-full">
              <input
                id="default-search"
                class="px-4 py-4 w-[90%] text-lg text-blue bg-white rounded-full border "
                placeholder="Add a review"
                required
                value={review}
                onChange={(e) => {
                  setReview(e.target.value);
                }}
              />
              {/* <button
                type="submit"
                class="text-blue absolute right-0 bottom-0 font-medium rounded-full text-lg w-10 h-14"
              >
                <i className="fa fa-arrow-right"></i>
              </button> */}
            </div>
          </form>
        </div>
      </div>
      <div className="w-full flex justify-end gap-5 mt-4">
        <Button
          primary
          onClick={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          Post Review
        </Button>
        <Button onClick={toggle}>Cancel</Button>
      </div>
    </div>
  );
}
