import React from "react";
import JsonQuery from "json-query";
import data from "../utils/new_file.json";

import { useEffect } from "react";
import { useState } from "react";
export default function Test() {
  const search = function (t) {
    // console.log("searching " + t);
    let text = "af";
    let res = [];
    data.forEach((e) => {
      if (res.length > 3) {
        return;
      }
      e.states.forEach((v) => {
        if (res.length > 3) {
          return;
        }
        v.cities.forEach((c) => {
          if (res.length > 3) {
            return;
          }
          if (c.toLowerCase().includes(t)) res.push(`${c},${v.name},${e.name}`);
        });
      });
      if (e.name.toLowerCase().includes(t)) res.push(e.name);
    });
    // console.log(res);
  };
  useEffect(() => {});
  //   const [val,setVal] = useState("")
  return (
    <input
      className="bg-red text-white"
      onChange={(e) => {
        search(e.target.value);
      }}
    />
  );
}
