import { applyTheme } from "./theme/utils";
import baseTheme from "./theme/colors";
import { Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./pages/home";
import HowItWorks from "./pages/howItWorks";
import Search from "./pages/search";
import Faq from "./pages/faq";
import ScrollTop from "./utils/scrolltop";
import ContactUs from "./pages/contactUs";
import Blogs from "./pages/blogs";
import SingleItinerary from "./pages/singleItinerary";
import Accounts from "./pages/accounts";
import Profile from "./pages/account/profile";
import { ItineraryForm, ItineraryMain } from "./pages/account/itineraries";
import Purchased from "./pages/account/purchased";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signup";
import AboutUs from "./pages/aboutUs";
import SingleBlog from "./pages/singleBlog";
import Confirmation from "./pages/confirm";
import AuthContext from "./context/AuthProvider";
import { useContext } from "react";
import ForgotPassword from "./pages/forgotPassword";
import ChangePassword from "./pages/account/changePassword";
import Test from "./pages/test";
import Payment from "./pages/account/payment";
import PaymentSuccess from "./pages/paymentSuccess";
import PaymentFailed from "./pages/paymentFailed";
import NotFound from "./pages/notFound";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Edit from "./pages/account/editItinerary";
import { Helmet } from "react-helmet";
function App() {
  const { auth, setAuth } = useContext(AuthContext);
  useEffect(() => {
    applyTheme(baseTheme);
    // console.log({jwt:localStorage.getItem("TSS__APP__JWT")});
    if (localStorage.getItem("TSS__APP__JWT"))
      if (!auth.jwt) {
        setAuth((prev) => ({
          ...prev,
          jwt: JSON.parse(localStorage.getItem("TSS__APP__JWT")),
        }));
      }
  }, []);
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/search" element={<Search />} /> */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="single-itinerary/:id" element={<SingleItinerary />} />
        <Route path="/accounts" element={<Accounts />}>
          {/* <Route path="" element={<Profile />} /> */}

          <Route path="" element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<Profile />} />
          <Route path="my-itineraries" element={<ItineraryMain />}>
            <Route path="add-new" element={<ItineraryForm />} />
            <Route path="edit/:id" element={<Edit />} />
          </Route>
          <Route path="purchased" element={<Purchased />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="payment" element={<Payment />} />
        </Route>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/single-blog/:id" element={<SingleBlog />} />
        <Route path="/forgot-password/:id" element={<ForgotPassword />} />
        <Route path="/test" element={<Test />} />
        <Route path="/privacy-policies" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/terms-conditions" element={<Terms />} />
      </Routes>
    </>
  );
}

export default App;
