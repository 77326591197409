import React from "react";

export default function Button(props) {
  // console.log(props);
  const createClass = () => {
    let base = "text-lg font-bold py-3 px-5 rounded border-[1px] ";
    if (props.disabled) {
      return base + "bg-grey text-blue border-blue";
    }
    if (props.primary)
      return (
        base +
        "bg-blue text-white border-blue hover:bg-white hover:border-blue hover:text-blue"
      );
    else {
      return (
        base +
        "bg-white text-blue border-blue hover:bg-blue hover:border-white hover:text-white"
      );
    }
  };

  return (
    <button {...props} className={createClass()}>
      {props.children}
    </button>
  );
}
