import React, { useState } from "react";
import { useEffect } from "react";

// const img = "./img/test.png";
export default function Card({
  title,
  location,
  days,
  price,
  img,
  author,
  score,
}) {
  useEffect(() => {
    if (score) return;
  }, []);

  return (
    <div className="w-[215px] bg-white h-[280px] md:w-[350px] md:h-[400px] p-2 shadow-custom">
      <div className="w-full h-[60%]">
        <img
          className="object-cover w-[100%] h-[100%]"
          src={img}
          alt={"Is indicative of itinerary"}
        />
      </div>
      <div className="mt-4">
        <div className="w-full flex justify-between">
          <span className="text-sm text-grey">{days} days</span>

          <span className="text-sm text-grey ">{location}</span>
        </div>
        <div className="flex">
          <Star rating={score} />
        </div>

        <div className="w-full">
          <span className="text-blue text-lg font-semibold">{title}</span>
        </div>
        <div className="w-full">
          <span className="text-blue text-sm">{author}</span>
        </div>
        <div className="w-full">
          <span className="text-blue font-bold">USD {price}$</span>
        </div>
      </div>
    </div>
  );
}

export function Star({ rating, change, clickable, width }) {
  const [ratings, setRatings] = useState(null);

  const w = width ? "w-[30px]" : "w-4";
  useEffect(() => {
    setRatings(rating);
  }, [rating]);
  if (ratings != null)
    return (
      <div>
        <ul className="flex justify-center">
          {[...Array(ratings).keys()].map((f, i) => {
            return (
              <li
                key={i}
                onClick={() => {
                  if (clickable) {
                    setRatings(i + 1);
                    if (change) change(i + 1);
                  }
                }}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="star"
                  class={w + " text-yellow mr-1"}
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  ></path>
                </svg>
              </li>
            );
          })}
          {[...Array(5 - ratings).keys()].map((f, i) => {
            return (
              <li
                key={i}
                onClick={() => {
                  if (clickable) {
                    setRatings((prev) => prev + i + 1);
                    if (change) change((prev) => prev + i + 1);
                  }
                }}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="star"
                  class={w + " text-grey mr-1"}
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  ></path>
                </svg>
              </li>
            );
          })}
        </ul>
      </div>
    );
  return <></>;
}
